export const PRIVATE_PERSON = 'privatePerson' as const
export const SELF_EMPLOYED = 'selfEmployed' as const
export const COMPANY = 'company' as const

export const ALL_LEGAL_FORMS = [
  PRIVATE_PERSON,
  SELF_EMPLOYED,
  COMPANY,
] as const

export type LegalForm = typeof ALL_LEGAL_FORMS[number]
