export const VEHICLE_USAGE_BASIC = 'basic' as const
export const VEHICLE_USAGE_RENTAL = 'rental' as const
export const VEHICLE_USAGE_TAXI = 'taxi' as const
export const VEHICLE_USAGE_DRIVING_SCHOOL = 'drivingSchool' as const
export const VEHICLE_USAGE_AMBULANCE = 'ambulance' as const
export const VEHICLE_USAGE_DANGEROUS_CARGO = 'dangerousCargo' as const
export const VEHICLE_USAGE_HISTORIC = 'historic' as const
export const VEHICLE_USAGE_SPECIAL_REG_NUMBER = 'specialRegNumber' as const
export const VEHICLE_USAGE_HISTORIC_SPECIAL_REG_NUMBER = 'historicSpecialRegNumber' as const
export const VEHICLE_USAGE_RIGHT_OF_WAY = 'rightOfWay' as const
export const VEHICLE_USAGE_INTERNATIONAL_TRANSPORT = 'internationalTransport' as const
export const ALL_VEHICLE_USAGES = [
  VEHICLE_USAGE_BASIC,
  VEHICLE_USAGE_RENTAL,
  VEHICLE_USAGE_TAXI,
  VEHICLE_USAGE_DRIVING_SCHOOL,
  VEHICLE_USAGE_AMBULANCE,
  VEHICLE_USAGE_DANGEROUS_CARGO,
  VEHICLE_USAGE_HISTORIC,
  VEHICLE_USAGE_SPECIAL_REG_NUMBER,
  VEHICLE_USAGE_HISTORIC_SPECIAL_REG_NUMBER,
  VEHICLE_USAGE_RIGHT_OF_WAY,
  VEHICLE_USAGE_INTERNATIONAL_TRANSPORT,
] as const

export type VehicleUsage = typeof ALL_VEHICLE_USAGES[number]

export const SUPPORTED_VEHICLE_USAGES = [
  VEHICLE_USAGE_BASIC,
] as const
