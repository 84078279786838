import {css} from 'styled-components'
import media from '../utils/media'
import type {FontStyle, LineHeight, Sizes, Weight} from './theme'
import theme from './theme'


type GenerateTypographyArgs = {
  size?: Sizes
  fontStyle?: FontStyle
  weight?: Weight
  lineHeight?: LineHeight | string
  withResponsive?: boolean
}

export const generateTypography = ({
  size = 14, fontStyle = 'normal', weight = 'regular', lineHeight: lineHeightOverride = '1.5', withResponsive = true,
}: GenerateTypographyArgs = {}) => {
  const sizes = theme.fonts.sizes[size]
  const {desktop, mobile, lineHeight} = sizes
  return (
    css`
    line-height: ${lineHeightOverride || lineHeight};
    font-size: ${desktop};
    font-weight: ${({theme}) => theme.fonts.weight[weight]};
    font-style: ${fontStyle};

    ${withResponsive && media.down('mobile')(css`
      font-size: ${mobile};
    `)}
  `)
}


export const bodyProps = {size: 14} as const
export const body = generateTypography(bodyProps)

export const h1Props = {size: 40, weight: 'bold', lineHeight: '1.2'} as const
export const h1 = generateTypography(h1Props)

export const h2Props = {size: 36} as const
export const h2 = generateTypography(h2Props)

export const h3Props = {size: 30, weight: 'bold', transform: 'uppercase'} as const
export const h3 = generateTypography(h3Props)

export const h4Props = {size: 24, weight: 'bold', transform: 'uppercase'} as const
export const h4 = generateTypography(h4Props)

export const h5Props = {size: 18, weight: 'semiBold'} as const
export const h5 = generateTypography(h5Props)

export const h6Props = {size: 14, weight: 'semiBold'} as const
export const h6 = generateTypography(h6Props)

export const inputProps = {size: 16, weight: 'bold', withResponsive: false} as const
export const input = generateTypography(inputProps)

export const smallProps = {size: 12} as const
export const small = generateTypography(smallProps)

export default css`
  body {
    ${body}
  }

  h1 {
    ${h1}
  }

  h2 {
    ${h2}
  }

  h3 {
    ${h3}
  }

  h4 {
    ${h4}
  }

  small {
    ${small}
  }

  input,
  textarea {
    ${input}

    &::placeholder {
      color: ${({theme}) => theme.colors.auxiliary7};
      font-weight: ${({theme}) => theme.fonts.weight.regular};
    }
  }

  strong {
    font-weight: ${({theme}) => theme.fonts.weight.bold};
  }

  i {
    font-style: ${({theme}) => theme.fonts.fontStyle.italic};
  }

  .sr-only {
    position: absolute;
    top: -9999px;
    left: -9999px;
    margin: -1px;
    border: 0;
    padding: 0;
    width: 1px;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
  }
`
