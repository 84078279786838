import {keysAsEnum} from '../../common/utils'


export const ADDON_GLASS = 'glass'
export const ADDON_THEFT = 'theft'
export const ALL_ADDONS = [
  ADDON_GLASS,
  ADDON_THEFT,
] as const

export type Addons = typeof ALL_ADDONS[number]

export const GLASS_COVERAGE_120 = '120' as const
export const GLASS_COVERAGE_300 = '300' as const
export const GLASS_COVERAGE_500 = '500' as const
export const GLASS_COVERAGE_750 = '750' as const
export const GLASS_COVERAGE_1000 = '1000' as const
export const GLASS_COVERAGE = {
  [GLASS_COVERAGE_120]: '5',
  [GLASS_COVERAGE_300]: '6',
  [GLASS_COVERAGE_500]: '7',
  [GLASS_COVERAGE_750]: '8',
  [GLASS_COVERAGE_1000]: '9',
}
export const GLASS_COVERAGE_DEFAULT = GLASS_COVERAGE_120

export const ALL_GLASS_COVERAGES = keysAsEnum(GLASS_COVERAGE)
export type GlassCoverages = typeof ALL_GLASS_COVERAGES[number]
