import type {ComponentProps, FC} from 'react'
import {ReactComponent as Alert} from '../../../assets/icons/alert.svg'
import Icon from '../Icon'


type IconProps = ComponentProps<typeof Icon>

const AlertIcon: FC<IconProps> = (props) => (
  <Icon icon={Alert} {...props} />
)

export default AlertIcon
