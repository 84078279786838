import {map} from 'lodash-es'
import {transparentize} from 'polished'
import {createGlobalStyle, css} from 'styled-components'
import {normalize} from 'styled-normalize'
import media from '../utils/media'
import theme from './theme'
import typography from './typography'


export default createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
  }

  /* stylelint-disable */
  ${normalize}
  /* stylelint-enable */

  @font-face {
    font-family: "Open Sans-fallback";
    src: local("Arial");
    ascent-override:101.18%;
    descent-override:27.73%;
    line-gap-override:0.00%;
    size-adjust:105.64%
  }

  :root {
    scroll-behavior: auto;
    font-family: ${theme.fonts.family}, "Open Sans-fallback";
    font-size: ${theme.responsive.zoom.fontSizeBase}px;
    ${media.down(theme.responsive.zoom.minimalViewport)(css`
      font-size: ${theme.responsive.zoom.fontSizeBase / (theme.responsive.zoom.minimalViewport) * 100}vw;
    `)}
  }

  html {
    overflow-x: hidden;
  }

  body {
    color: ${theme.colors.text};
    background-color: ${theme.colors.background};
    overflow-x: hidden;
  }

  a {
    text-decoration: none;
    color: ${theme.colors.text};

    &[href^="mailto"],
    &[href^="tel"] {
      text-decoration: underline;
    }
    &:hover,
    &:focus {
      outline: none;
    }
  }

  button {
    &:hover,
    &:focus {
      outline: none;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  p:not(:first-child) {
    margin-block-start: ${({theme}) => theme.spacer};
    margin-block-end: 0;
  }

  hr {
    border-color: ${({theme}) => transparentize(0.73, theme.colors.text)};
    margin: 0 -0.5rem;
  }

  .text-color {
    ${({theme}) => map(theme.colors, (color, key) => {
    return css`
        &-${key} {
          color: ${color}
        }
      `
  })}
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
  }

  .text-underline {
    text-decoration: underline;
  }

  ${typography}
`
