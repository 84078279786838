exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-comparison-tsx": () => import("./../../../src/templates/comparison.tsx" /* webpackChunkName: "component---src-templates-comparison-tsx" */),
  "component---src-templates-contract-allianz-index-tsx": () => import("./../../../src/templates/contract/allianz/index.tsx" /* webpackChunkName: "component---src-templates-contract-allianz-index-tsx" */),
  "component---src-templates-contract-allianz-new-tsx": () => import("./../../../src/templates/contract/allianz/new.tsx" /* webpackChunkName: "component---src-templates-contract-allianz-new-tsx" */),
  "component---src-templates-contract-csob-index-tsx": () => import("./../../../src/templates/contract/csob/index.tsx" /* webpackChunkName: "component---src-templates-contract-csob-index-tsx" */),
  "component---src-templates-contract-csob-new-tsx": () => import("./../../../src/templates/contract/csob/new.tsx" /* webpackChunkName: "component---src-templates-contract-csob-new-tsx" */),
  "component---src-templates-contract-generali-index-tsx": () => import("./../../../src/templates/contract/generali/index.tsx" /* webpackChunkName: "component---src-templates-contract-generali-index-tsx" */),
  "component---src-templates-contract-generali-new-tsx": () => import("./../../../src/templates/contract/generali/new.tsx" /* webpackChunkName: "component---src-templates-contract-generali-new-tsx" */),
  "component---src-templates-contract-genertel-index-tsx": () => import("./../../../src/templates/contract/genertel/index.tsx" /* webpackChunkName: "component---src-templates-contract-genertel-index-tsx" */),
  "component---src-templates-contract-genertel-new-tsx": () => import("./../../../src/templates/contract/genertel/new.tsx" /* webpackChunkName: "component---src-templates-contract-genertel-new-tsx" */),
  "component---src-templates-contract-komunalna-index-tsx": () => import("./../../../src/templates/contract/komunalna/index.tsx" /* webpackChunkName: "component---src-templates-contract-komunalna-index-tsx" */),
  "component---src-templates-contract-komunalna-new-tsx": () => import("./../../../src/templates/contract/komunalna/new.tsx" /* webpackChunkName: "component---src-templates-contract-komunalna-new-tsx" */),
  "component---src-templates-contract-kooperativa-index-tsx": () => import("./../../../src/templates/contract/kooperativa/index.tsx" /* webpackChunkName: "component---src-templates-contract-kooperativa-index-tsx" */),
  "component---src-templates-contract-kooperativa-new-tsx": () => import("./../../../src/templates/contract/kooperativa/new.tsx" /* webpackChunkName: "component---src-templates-contract-kooperativa-new-tsx" */),
  "component---src-templates-contract-union-index-tsx": () => import("./../../../src/templates/contract/union/index.tsx" /* webpackChunkName: "component---src-templates-contract-union-index-tsx" */),
  "component---src-templates-contract-union-new-tsx": () => import("./../../../src/templates/contract/union/new.tsx" /* webpackChunkName: "component---src-templates-contract-union-new-tsx" */),
  "component---src-templates-contract-wustenrot-index-tsx": () => import("./../../../src/templates/contract/wustenrot/index.tsx" /* webpackChunkName: "component---src-templates-contract-wustenrot-index-tsx" */),
  "component---src-templates-contract-wustenrot-new-tsx": () => import("./../../../src/templates/contract/wustenrot/new.tsx" /* webpackChunkName: "component---src-templates-contract-wustenrot-new-tsx" */),
  "component---src-templates-form-tsx": () => import("./../../../src/templates/form.tsx" /* webpackChunkName: "component---src-templates-form-tsx" */),
  "component---src-templates-insurance-companies-tsx": () => import("./../../../src/templates/insuranceCompanies.tsx" /* webpackChunkName: "component---src-templates-insurance-companies-tsx" */),
  "component---src-templates-insurance-company-tsx": () => import("./../../../src/templates/insuranceCompany.tsx" /* webpackChunkName: "component---src-templates-insurance-company-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

