import {compact} from 'lodash-es'


export const getCookie = (name: string) => {
  if (typeof document !== 'undefined') {
    return document.cookie.split('; ').reduce((r, v) => {
      const parts = v.split('=')
      return parts[0] === name ? decodeURIComponent(parts[1]) : r
    }, '')
  }
}

export const setCookie = ({name, value, days = 7, path = '/', domain} :
  {name: string, value: string | number | boolean, days?: number, path?: string, domain?: string}
) => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString()
  if (typeof document !== 'undefined') {
    document.cookie = compact([
      `${name}=${encodeURIComponent(value)}`,
      expires && `expires=${expires}`,
      path && `path=${path}`,
      domain && `domain=${domain}`,
    ]).join('; ')
  }
}

export const deleteCookie = (name: string, path: string) => {
  setCookie({name, value: '', days: -1, path})
}
