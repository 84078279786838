import type {ComponentType} from 'react'
import {lazy} from 'react'


const waitForLoad = () => (new Promise((resolve) => {
  if (typeof window === 'undefined') return

  const handlePageLoad = (e?: Event) => {
    if (e) window.removeEventListener('load', handlePageLoad)
    if ('requestIdleCallback' in window) {
      const callback = (deadline: IdleDeadline) => {
        if (deadline.timeRemaining() > 1000) return window.requestIdleCallback(callback)
        resolve(e)
      }
      window.requestIdleCallback(callback)
    } else {
      resolve(e)
    }
  }

  if (document.readyState === 'complete') {
    handlePageLoad()
  } else {
    window.addEventListener('load', handlePageLoad)
  }
}))

export const lazyAfterLoad = <Component extends ComponentType>(component: () => Promise<{default: Component }>) => (
  lazy(async () => {
    await waitForLoad()
    return component()
  })
)
