import {isEmpty} from 'lodash-es'
import type {ComponentProps, FC} from 'react'
import {Modal, AlertContainer, Text, Button, SSRSuspense, AlertIcon} from 'uikit'
import useAlert from '../hooks/useAlert'


type ModalProps = ComponentProps<typeof Modal>

const ErrorModal: FC<ModalProps> = ({...props}) => {
  const {error, setError, options} = useAlert()

  const handleAlertClose = () => {
    setError(false)

    if (options?.onClose) {
      options.onClose()
    }
  }

  return (
    <SSRSuspense fallback={<div />}>
      <Modal open={!isEmpty(error)} onClose={handleAlertClose} variant="wide" transparent {...props}>
        <AlertContainer>
          <AlertIcon noFill />
          <Text component="p" textStyle="body" align="center" dangerouslySetInnerHTML={{__html: error}} />
          <Button fullWidth onClick={handleAlertClose}>{options?.buttonText}</Button>
        </AlertContainer>
      </Modal>
    </SSRSuspense>
  )
}

export default ErrorModal
