export const ADDON_ANIMAL = 'animal'
export const ADDON_ELEMENT = 'element'
export const ADDON_GLASS = 'glass'
export const ADDON_INJURY = 'injury'
export const ADDON_LEGAL_AID = 'legalAid'
export const ALL_ADDONS = [
  ADDON_ANIMAL,
  ADDON_ELEMENT,
  ADDON_GLASS,
  ADDON_INJURY,
  ADDON_LEGAL_AID,
] as const

export type Addons = typeof ALL_ADDONS[number]

export const GLASS_COVERAGE_300 = '300' as const
export const GLASS_COVERAGE_500 = '500' as const
export const GLASS_COVERAGE_800 = '800' as const
export const GLASS_COVERAGE_1000 = '1000' as const
export const GLASS_COVERAGE = {
  [GLASS_COVERAGE_300]: 'PS300',
  [GLASS_COVERAGE_500]: 'PS500',
  [GLASS_COVERAGE_800]: 'PS800',
  [GLASS_COVERAGE_1000]: 'PS1000',
}
export const GLASS_COVERAGE_DEFAULT = GLASS_COVERAGE_300
