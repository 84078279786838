import z from 'zod'
import {FUEL_BENZIN, FUEL_ELEKTRO} from '../../constants/fuels'
import * as legalForms from '../../constants/legalFormTypes'
import {PRIVATE_PERSON} from '../../constants/legalFormTypes'
import {VEHICLE_TYPE_CAR_M1} from '../../constants/vehicleTypes'
import * as common from './common'
import * as types from './types'
import {validateBornNumber} from './validations'


export const vehicle = z.object({
  vehicleType: common.vehicle.type,
  brand: common.vehicle.brand,
  model: common.vehicle.model,
  weight: common.vehicle.weight,
  volume: common.vehicle.volume,
  power: common.vehicle.power,
  fuel: common.vehicle.fuel,
  seats: common.vehicle.seats,
  productionYear: common.vehicle.productionYear,
}).superRefine(({fuel, volume}, ctx) => {
  if (fuel !== FUEL_ELEKTRO && volume < 1) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['volume'],
      message: 'Objem motora musí byť väčší ako 1.',
    })
  }
  return z.NEVER
})

export type Vehicle = z.infer<typeof vehicle>


export const extendedVehicle = z.object({
  vehicleType: common.extendedVehicleType(),
  brand: common.vehicle.brand,
  model: common.vehicle.model,
  weight: common.vehicle.weight,
  volume: common.vehicle.volume,
  power: common.vehicle.power,
  fuel: common.extendedFuel(),
  seats: common.vehicle.seats,
  productionYear: common.vehicle.productionYear,
}).superRefine(({fuel, volume}, ctx) => {
  if (fuel !== FUEL_ELEKTRO && volume < 1) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['volume'],
      message: 'Objem motora musí byť väčší ako 1.',
    })
  }
  return z.NEVER
})

export type ExtendedVehicle = z.infer<typeof extendedVehicle>

export const person = z.union([
  z.object({
    legalForm: types.literal(legalForms.PRIVATE_PERSON),
    zipCode: common.person.zipCode,
    city: common.person.city,
    birthday: common.person.birthday,
    bornNumber: common.person.bornNumber.optional(),
    ztp: common.person.ztp,
    companyNumber: z.undefined(),
  }),
  z.object({
    legalForm: types.literal(legalForms.COMPANY),
    zipCode: common.person.zipCode,
    city: common.person.city,
    birthday: z.undefined(),
    bornNumber: z.undefined(),
    ztp: z.literal(false).optional(),
    companyNumber: common.person.companyNumber,
  }),
  z.object({
    legalForm: types.literal(legalForms.SELF_EMPLOYED),
    zipCode: common.person.zipCode,
    city: common.person.city,
    birthday: z.undefined(),
    bornNumber: z.undefined(),
    ztp: z.literal(false).optional(),
    companyNumber: common.person.companyNumber,
  }),
]).superRefine(({legalForm, birthday, bornNumber}, ctx) => {
  const bornNumberError = validateBornNumber(legalForm, birthday, bornNumber)
  if (bornNumberError) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['bornNumber'],
      message: bornNumberError,
    })
  }
  return z.NEVER
})

export type Person = z.infer<typeof person>

export const damageClaims = z.object({
  didHavePZP: types.boolean(),
  claims12: common.damageClaimsCount,
  claims24: common.damageClaimsCount,
  claims36: common.damageClaimsCount,
  claims48: common.damageClaimsCount,
  claims60: common.damageClaimsCount,
  claims72: common.damageClaimsCount,
  claims120: common.damageClaimsCount,
})

export type DamageClaims = z.infer<typeof damageClaims>

export const discounts = z.discriminatedUnion('legalForm', [
  z.object({
    legalForm: types.literal(legalForms.PRIVATE_PERSON),
    csobOtherContractsDiscount: types.boolean().optional(),
    csobEmergencyBrakingSystem: types.boolean().optional(),
    csobParkingAssistantSensors: types.boolean().optional(),
    unionUzpDiscount: types.boolean().optional(),
    uniqaOtherContractNumber: z.string().trim().min(9).max(10).regex(/^[0-9]+$/).optional(),
  }),
  z.object({
    legalForm: types.literal(legalForms.COMPANY),
    csobOtherContractsDiscount: types.boolean().optional(),
    csobEmergencyBrakingSystem: types.boolean().optional(),
    csobParkingAssistantSensors: types.boolean().optional(),
    unionUzpDiscount: z.undefined(),
    uniqaOtherContractNumber: z.string().trim().min(9).max(10).regex(/^[0-9]+$/).optional(),
  }),
  z.object({
    legalForm: types.literal(legalForms.SELF_EMPLOYED),
    csobOtherContractsDiscount: types.boolean().optional(),
    csobEmergencyBrakingSystem: types.boolean().optional(),
    csobParkingAssistantSensors: types.boolean().optional(),
    unionUzpDiscount: z.undefined(),
    uniqaOtherContractNumber: z.string().trim().min(9).max(10).regex(/^[0-9]+$/).optional(),
  }),
])

export type Discounts = z.infer<typeof discounts>

export const calculation = vehicle
  .and(common.vehicleIdentificationOptional)
  .and(person)
  .and(common.telephoneOptional)
  .and(damageClaims)
  .and(discounts)

export type Calculation = z.infer<typeof calculation>

export const extendedCalculation = extendedVehicle
  .and(common.vehicleIdentificationOptional)
  .and(person)
  .and(common.telephoneOptional)
  .and(damageClaims)
  .and(discounts)

export type ExtendedCalculation = z.infer<typeof extendedCalculation>

export const calculationUuid = types.uuid().brand<'CalculationUuid'>()
export type CalculationUuid = z.infer<typeof calculationUuid>

const calculationResultUuid = types.uuid().brand<'CalculationResultUuid'>()
export type CalculationResultUuid = z.infer<typeof calculationResultUuid>

export const calculationExample = {
  licensePlate: 'BA111EX',
  vin: 'AAAAAAAAAA0000000',
  vehicleType: VEHICLE_TYPE_CAR_M1,
  brand: 'bmw',
  model: 'rad5',
  weight: 2320,
  volume: 2926,
  power: 159,
  fuel: FUEL_BENZIN,
  seats: 5,
  productionYear: 2021,
  legalForm: PRIVATE_PERSON,
  zipCode: '93101',
  city: 'Šamorín',
  birthday: '1980-03-24',
  ztp: true,
  didHavePZP: true,
  claims12: 1,
  claims24: 1,
  claims36: 1,
  claims48: 1,
  claims60: 1,
  claims72: 1,
  claims120: 1,
  unionUzpDiscount: true,
  csobOtherContractsDiscount: true,
  csobEmergencyBrakingSystem: true,
  csobParkingAssistantSensor: true,
  uniqaCrossSellDiscount: '123456789',
}
