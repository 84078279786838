import invariant from 'invariant'
import {isNumber, isObject, isString, join} from 'lodash-es'
import type {ErrorMapCtx, ZodIssue} from 'zod'
import {ZodIssueCode, ZodParsedType} from 'zod'


const plural = (count: number | bigint, zero: string, one: string, few: string, many: string) => {
  if (count === 0) return zero
  if (count === 1) return one
  if (count < 5) return few
  return many
}

const types = {
  function: 'funkcia',
  number: 'číslo',
  string: 'text',
  nan: 'nie je číslo',
  integer: 'celé číslo',
  float: 'desatinné číslo',
  boolean: 'pravidvostná hodnota',
  date: 'dátum',
  bigint: 'veľké číslo',
  undefined: 'nedefinované',
  symbol: 'symbol',
  null: 'neexistujúca hodnota',
  array: 'pole',
  object: 'objekt',
  unknown: 'neznáma hodnota',
  promise: 'prísľub',
  void: 'prázdny',
  never: 'nikdy',
  map: 'zobrazenie',
  set: 'množina',
}

// eslint-disable-next-line complexity
const zodErrorMap = (issue: ZodIssue, ctx: ErrorMapCtx) => {
  let message = ''
  switch (issue.code) {
    case ZodIssueCode.invalid_type:
      if (issue.received === ZodParsedType.undefined) {
        message = 'Povinné'
      } else {
        message = `Neplatná hodnota. Zadajte ${types[issue.expected]}`
      }
      break
    case ZodIssueCode.invalid_literal:
      message = `Neplatná hodnota. Povolené hodnoty: ${JSON.stringify(issue.expected)}`
      break
    case ZodIssueCode.unrecognized_keys:
      message = `Nerozpoznané kľúče v objekte: ${join(
        issue.keys,
        ', '
      )}`
      break
    case ZodIssueCode.invalid_union:
      message = 'Neplatná hodnota'
      break
    case ZodIssueCode.invalid_union_discriminator:
      message = `Neplatná hodnota. Povolené hodnoty: ${join(
        issue.options
      )}`
      break
    case ZodIssueCode.invalid_enum_value:
      message = `Neplatná hodnota. Povolené hodnoty: ${join(
        issue.options
      )}, zadaná '${issue.received}'`
      break
    case ZodIssueCode.invalid_arguments:
      message = 'Naplatné argumenty funkcie'
      break
    case ZodIssueCode.invalid_return_type:
      message = 'Naplatný typ výstupu funckie'
      break
    case ZodIssueCode.invalid_date:
      message = 'Neplatný dátum'
      break
    case ZodIssueCode.invalid_string:
      if (isObject(issue.validation)) {
        if ('startsWith' in issue.validation) {
          message = `Neplatná hodnota: musí začínať "${issue.validation.startsWith}"`
        } else if ('endsWith' in issue.validation) {
          message = `Neplatná hodnota: musí končiť "${issue.validation.endsWith}"`
        } else if (isString(issue.validation)) {
          invariant(false, issue.validation)
        }
      } else if (issue.validation !== 'regex') {
        message = `Neplatný ${issue.validation}`
      } else {
        message = 'Neplatná hodnota'
      }
      break
    case ZodIssueCode.too_small:
      if (issue.type === 'array') {
        message = `Pole musí obsahovať ${
          issue.exact ? 'presne' : issue.inclusive ? 'aspoň' : 'viac ako'
        } ${issue.minimum} element(s)`
      } else if (issue.type === 'string') {
        message = `Text musí obsahovať ${
          issue.exact ? 'presne' : issue.inclusive ? 'aspoň' : 'viac ako'
        } ${issue.minimum} ${plural(issue.minimum, 'znakov', 'znak', 'znaky', 'znakov')}`
      } else if (issue.type === 'number') {
        message = `Číslo musí byť ${
          issue.exact
            ? 'presne '
            : issue.inclusive
              ? 'väčšie alebo rovné ako '
              : 'väčšie ako '
        }${issue.minimum}`
      } else if (issue.type === 'date') {
        message = `Dátum musí byť ${
          issue.exact
            ? 'presne '
            : issue.inclusive
              ? 'väčší alebo rovný ako '
              : 'väčší ako '
        }${isNumber(issue.minimum) ? new Date(issue.minimum) : null}`
      } else message = 'Neplatná hodnota'
      break
    case ZodIssueCode.too_big:
      if (issue.type === 'array') {
        message = `Pole musí obsahovať ${
          issue.exact ? 'presne' : issue.inclusive ? 'najviac' : 'menej ako'
        } ${issue.maximum} ${plural(issue.maximum, 'prvkov', 'prvok', 'prvky', 'prvkov')}`
      } else if (issue.type === 'string') {
        message = `Text musí obsahovať ${
          issue.exact ? 'presne' : issue.inclusive ? 'najviac' : 'menej ako'
        } ${issue.maximum} ${plural(issue.maximum, 'znakov', 'znak', 'znaky', 'znakov')}`
      } else if (issue.type === 'number') {
        message = `Číslo musí byť ${
          issue.exact
            ? 'presne '
            : issue.inclusive
              ? 'menšie alebo rovné ako'
              : 'menšie ako'
        } ${issue.maximum}`
      } else if (issue.type === 'date') {
        message = `Dátum musí byť ${
          issue.exact
            ? 'presne '
            : issue.inclusive
              ? 'menší alebo rovný ako'
              : 'menší ako'
        } ${isNumber(issue.maximum) ? new Date(issue.maximum) : null}`
      } else message = 'Neplatná hodnota'
      break
    case ZodIssueCode.custom:
      message = 'Neplatná hodnota'
      break
    case ZodIssueCode.invalid_intersection_types:
      message = 'Hodnota nespĺňa všetky podmienky'
      break
    case ZodIssueCode.not_multiple_of:
      message = `Číslo musí byť násobok ${issue.multipleOf}`
      break
    case ZodIssueCode.not_finite:
      message = 'Číslo musí byť konečné'
      break
    default:
      message = ctx.defaultError
  }
  return {message}
}

export default zodErrorMap
