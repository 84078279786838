import {noop} from 'lodash-es'
import type {FC, ReactNode} from 'react'
import {useMemo, createContext, useState, useContext} from 'react'


type LoadingContextType = {
  loading: boolean
  setLoading: (loading: boolean) => void
}

type LoadingProviderProps = {
  children: ReactNode
}

const LoadingContext = createContext<LoadingContextType>({
  loading: false,
  setLoading: noop,
})

export const LoadingProvider: FC<LoadingProviderProps> = ({children}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const memoizedValue = useMemo(() => ({
    loading,
    setLoading,
  }), [loading])
  return (
    <LoadingContext.Provider value={memoizedValue}>
      {children}
    </LoadingContext.Provider>
  )
}

const useLoading = () => {
  const {loading, setLoading} = useContext(LoadingContext)
  return {loading, setLoading}
}

export default useLoading
