import {getYear, isSameDay} from 'date-fns'
import {keys, toNumber} from 'lodash'


// According to https://sk.wikipedia.org/wiki/Rodné_číslo, based on act no. 301/1995 on born number, paragraph 2,
// the first two digits of the born number express the last two digits of the person's year of birth. The born
// number assigned to a person born before 1953 is nine digits and since 1954 is ten digits long.
export const getBornYearFromBornNumber = (bornNumber: string) => {
  const year = bornNumber.substring(0, 2)
  const century = bornNumber.length === 9 || (bornNumber.length === 10 && Number(year) > 53) ? '19' : '20'
  return toNumber(`${century}${year}`)
}

export const validateBornNumber = (bornNumber: string) => {
  const year = getBornYearFromBornNumber(bornNumber)
  // Born number can't be from the future
  if (year > getYear(new Date())) return false
  // Before 1954, born number is 9 digits long
  if (year < 1954) return bornNumber.length === 9
  // Since 1954, it's 10 digits long with control number
  return bornNumber.length === 10 && Number(bornNumber) % 11 === 0
}

export const getBirthdayFromBornNumber = (bornNumber: string) => {
  const year = getBornYearFromBornNumber(bornNumber)
  const monthCode = toNumber(bornNumber.substring(2, 4))
  const month = monthCode > 50 ? monthCode - 50 : monthCode
  const day = toNumber(bornNumber.substring(4, 6))
  return new Date(year, month - 1, day)
}

export const bornNumberMatchesBirthday = (bornNumber: string, birthday: string) => {
  const birtdayFromBornNumber = getBirthdayFromBornNumber(bornNumber)
  return isSameDay(birtdayFromBornNumber, new Date(birthday))
}

export const drivingLicenceYearIsValid = (drivingLicenceYear: number, birthday: string) => {
  return drivingLicenceYear - getYear(new Date(birthday)) >= 15
}

export const registrationDateIsValid = (registrationDate: string, productionYear: number) => {
  return productionYear <= getYear(new Date(registrationDate))
}

export const keysAsEnum = <T>(obj: T) => keys(obj) as (keyof T)[]

export const DEFAULT_BEGIN_DATE_MAX_MONTHS = 6
