import type {FC, HTMLAttributes, ReactNode} from 'react'
import {Children} from 'react'
import styled from 'styled-components'


const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div:not(:last-child) {
    margin-bottom: ${({theme}) => `calc(${theme.spacer} * 2) `};
  }
`

type DivProps = HTMLAttributes<HTMLDivElement>

type AlertContainerProps = {
  children: ReactNode
}

const AlertContainer: FC<DivProps & AlertContainerProps> = ({children, ...props}) => (
  <StyledContainer {...props}>
    {Children.map(children, (child) => {
      return (
        <div>
          {child}
        </div>
      )
    })}
  </StyledContainer>
)

export default AlertContainer
