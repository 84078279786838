export const ADDON_MINICASCO = 'minicasco'
export const ADDON_THEFT = 'theft'
export const ADDON_UNEXPECTED_EXPENSES = 'unexpectedExpenses'
export const ALL_ADDONS = [
  ADDON_MINICASCO,
  ADDON_THEFT,
  ADDON_UNEXPECTED_EXPENSES,
] as const

export type Addons = typeof ALL_ADDONS[number]

export const THEFT_COVERAGE_BASIC = 'theftBasicCoverage' as const
export const THEFT_COVERAGE_EXTRA = 'theftExtraCoverage' as const
export const THEFT_COVERAGES = [
  THEFT_COVERAGE_BASIC,
  THEFT_COVERAGE_EXTRA,
] as const
export const THEFT_COVERAGE_DEFAULT = THEFT_COVERAGE_BASIC

export type TheftCoverages = typeof THEFT_COVERAGES[number]

export const MINICASCO_COVERAGE_BASIC = 'minicascoBasicCoverage' as const
export const MINICASCO_COVERAGE_EXTRA = 'minicascoExtraCoverage' as const
export const MINICASCO_COVERAGES = [
  MINICASCO_COVERAGE_BASIC,
  MINICASCO_COVERAGE_EXTRA,
] as const
export const MINICASCO_COVERAGE_DEFAULT = MINICASCO_COVERAGE_BASIC

export type MinicascoCoverages = typeof MINICASCO_COVERAGES[number]
