import {format, isValid, parse, isMatch} from 'date-fns'
import type {ValidationErrors} from 'final-form'
import {ARRAY_ERROR, FORM_ERROR} from 'final-form'
import {flatMap, get, includes, isArray, isEmpty, isString, map, set, split} from 'lodash-es'
import type {ReactNode} from 'react'
import type {FieldMetaState} from 'react-final-form'
import type {ZodIssue} from 'zod'
import z, {ZodIssueCode, ZodParsedType} from 'zod'
import * as schemas from '../../../common/schemas'
import type {VehicleIdentificationSome} from '../../../common/schemas/common'
import zodErrorMap from '../translations/zodErrorMap'


export type ShowError = (args: {meta: FieldMetaState<unknown>, helperText: ReactNode}) => {
  isError?: boolean, helperTextOrError: ReactNode
}

export const showErrorOnBlur: ShowError = ({
  meta: {submitError, dirtySinceLastSubmit, error, touched}, helperText,
}) => {
  const isError = Boolean(((submitError && !dirtySinceLastSubmit) || error) && touched)
  const helperTextOrError = isError ? error || submitError : helperText

  return {isError, helperTextOrError}
}

export const showErrorAfterSubmit: ShowError = ({
  meta: {submitError, submitFailed, error, valid, dirtySinceLastSubmit}, helperText,
}) => {
  const isError = submitFailed && !valid && !dirtySinceLastSubmit
  const helperTextOrError = isError ? error || submitError : helperText

  return {isError, helperTextOrError}
}


type HelperText = {
  helperText?: ReactNode
}

export const hideError = ({helperText}: HelperText) => {
  return {isError: false, helperTextOrError: helperText}
}

z.setErrorMap(zodErrorMap)

const parseErrorSchema = (zodErrors: ZodIssue[]) => {
  const errors: ValidationErrors = {}
  for (; zodErrors.length;) {
    const [error] = zodErrors

    if (
      !get(errors, error.path) &&
      !(error.code === ZodIssueCode.invalid_type && error.expected === ZodParsedType.undefined)
    ) {
      if (error.code === ZodIssueCode.invalid_type && error.expected === ZodParsedType.array) {
        // Empty array must be kept as array for final form to work
        if (!isArray(get(errors, error.path))) set(errors, error.path, [])
        error.path.push(ARRAY_ERROR)
      }

      if ('unionErrors' in error) {
        const [unionError] = error.unionErrors[0].errors
        set(errors, error.path, unionError.message)
      } else {
        set(errors, error.path, error.message)
      }
    }

    if ('unionErrors' in error) {
      error.unionErrors.forEach((unionError) => unionError.errors.forEach((e) => zodErrors.push(e)))
    }

    zodErrors.shift()
  }

  return errors
}

export const validator = <T extends z.ZodType>(schema: T, opts: Record<string, unknown> = {}) => {
  return (passedValues: Record<string, unknown>) => {
    const result = schema.safeParse(passedValues, opts)

    if (result.success) {
      return {errors: undefined, values: result.data}
    }

    const parsedErrors = parseErrorSchema(result.error.issues)

    if (isEmpty(parsedErrors)) {
      return {errors: {[FORM_ERROR]: true}, values: undefined}
    }

    return {errors: parsedErrors, values: undefined}
  }
}


type Values = {
  licensePlate?: string
  vin?: string
}

export const getRegistrationByEcv = (values: Values, isEcv: boolean): VehicleIdentificationSome | null => {
  if (!values.licensePlate && !values.vin) return null
  if (isEcv && !values.licensePlate || !isEcv && !values.vin) return null

  if (values.licensePlate) return {licensePlate: values.licensePlate, vin: undefined}
  if (values.vin) return {licensePlate: undefined, vin: values.vin}

  return null
}

export const parseDate = (birthday: string) => {
  if (!birthday) return undefined
  if (isMatch(birthday, 'yyyy-MM-dd')) return birthday
  const date = parse(birthday, 'dd.MM.yyyy', new Date())
  if (isValid(date)) {
    return format(new Date(date), 'yyyy-MM-dd')
  }
  return undefined
}


type ZipCodeValue = {
  zipCode: string | number
  city: string
}

type ZipCodes = {
  [x: string]: string[];
}

export const getZipCodeValue = ({zipCode, city}: ZipCodeValue) => {
  return {value: `${zipCode}|${city}`, label: `${zipCode} (${city})`}
}

export const zipCodesToOptions = (zipCodes: ZipCodes) => {
  return flatMap(zipCodes, (cities, zipCode) => {
    return map(cities, (city) => getZipCodeValue({city, zipCode}))
  })
}

export const getValuesFromZipCodeValue = (value: unknown) => {
  if (!isString(value)) return {}
  if (includes(value, '|')) {
    const [zipCode, city] = split(value, '|')
    return {city, zipCode}
  }
  return {city: value, zipCode: value}
}

export const validateVehicleData = (values: Record<string, unknown>) => {
  return schemas.calculations.vehicle.safeParse(values).success
}
