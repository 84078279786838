import {isEmpty, pick, sortBy} from 'lodash-es'
import type {AbtestCurrent} from '../../../common/abtests'
import {
  conf,
  COOKIE_NAME,
  COOKIE_SEPARATOR,
  formatAbtests,
  getEnabledTests,
  isVariantActive as isVariantActiveCommon,
  parseAbtests,
} from '../../../common/abtests'
import {getCookie, setCookie} from '../../../common/cookies'
import type {AbTest} from '../../../constants/abtests'


const getAbtests = () => {
  const cookie = getCookie(COOKIE_NAME)
  const enabledTests = getEnabledTests()
  return pick(parseAbtests(cookie ? cookie.split(COOKIE_SEPARATOR) : []), enabledTests)
}

const saveAbtests = (abtests: AbtestCurrent) => {
  const str = formatAbtests(abtests).join(COOKIE_SEPARATOR)
  // eslint-disable-next-line no-process-env
  if (str) setCookie({name: COOKIE_NAME, value: str, days: 30, domain: process.env.GATSBY_COOKIE_DOMAIN})
  return str
}

export const setupAbtests = () => {
  const abtests = getAbtests()
  if (!isEmpty(abtests)) return saveAbtests(abtests)
  const enabledTests = getEnabledTests()
  const sortedTests = sortBy(enabledTests, (test) => conf[test].priority)
  const picked: AbtestCurrent = {}
  for (const test of sortedTests) {
    const variant = conf[test].pickVariant(picked)
    if (variant) picked[test] = variant
  }
  return saveAbtests(picked)
}

export const isVariantActive = <T extends AbTest>(test: T, variant: (typeof conf)[T]['variants'][number]) => {
  const abtests = getAbtests()
  return isVariantActiveCommon(abtests, test, variant)
}
