export const ADDON_ANIMAL = 'animal'
export const ADDON_ASSISTANCE = 'assistance'
export const ADDON_GLASS = 'glass'
export const ADDON_THEFT = 'theft'
export const ALL_ADDONS = [
  ADDON_ANIMAL,
  ADDON_ASSISTANCE,
  ADDON_GLASS,
  ADDON_THEFT,
] as const

export type Addons = typeof ALL_ADDONS[number]

export const ANIMAL_COVERAGE_1000 = '1000'
export const ANIMAL_COVERAGE_2000 = '2000'
export const ANIMAL_COVERAGE = {
  [ANIMAL_COVERAGE_1000]: '801_0',
  [ANIMAL_COVERAGE_2000]: '802_0',
}
export const ANIMAL_COVERAGE_DEFAULT = ANIMAL_COVERAGE_1000

export const ASSISTANCE_COVERAGE_SPECIAL = 'special' as const
export const ASSISTANCE_COVERAGE_OPTIMUM = 'optimum' as const
export const ALL_ASSISTANCE_COVERAGES = [
  ASSISTANCE_COVERAGE_SPECIAL,
  ASSISTANCE_COVERAGE_OPTIMUM,
]
export const ASSISTANCE_COVERAGE_DEFAULT = ASSISTANCE_COVERAGE_SPECIAL

export const GLASS_COVERAGE_200 = '200'
export const GLASS_COVERAGE_400 = '400'
export const GLASS_COVERAGE = {
  [GLASS_COVERAGE_200]: '1_0',
  [GLASS_COVERAGE_400]: '2_0',
}
export const GLASS_COVERAGE_DEFAULT = GLASS_COVERAGE_200

export const THEFT_COVERAGE_2500 = '2500'
export const THEFT_COVERAGE_5000 = '5000'
export const THEFT_COVERAGE = {
  [THEFT_COVERAGE_2500]: '701_0',
  [THEFT_COVERAGE_5000]: '702_0',
}
export const THEFT_COVERAGE_DEFAULT = THEFT_COVERAGE_2500
