import invariant from 'invariant'
import {omit} from 'lodash-es'
import type {ElementType, FC, HTMLAttributes} from 'react'
import styled, {css} from 'styled-components'
import type {FontStyle, LineHeight, Sizes, Weight, Color} from '../../theme/theme'
import * as typography from '../../theme/typography'


export const stylesToProps = {
  body: typography.bodyProps,
  h1: typography.h1Props,
  h2: typography.h2Props,
  h3: typography.h3Props,
  h4: typography.h4Props,
  h5: typography.h5Props,
  h6: typography.h6Props,
  input: typography.inputProps,
  small: typography.smallProps,
}

type SpanProps = HTMLAttributes<HTMLSpanElement>

type TextProps = {
  textStyle?: keyof typeof stylesToProps
  component?: ElementType
  color?: Color
  noWrap?: boolean
  align?: 'left' | 'right' | 'center' | 'justify'
  withIcon?: boolean
  transform?: 'uppercase' | 'lowercase' | 'capitalize'
  size?: Sizes
  fontStyle?: FontStyle
  weight?: Weight
  lineHeight?: LineHeight | string
  withResponsive?: boolean
}

type StyledSpanProps = {
  $size: TextProps['size']
  $fontStyle: TextProps['fontStyle']
  $weight: TextProps['weight']
  lineHeight: TextProps['lineHeight']
  withResponsive: TextProps['withResponsive']
  $color: TextProps['color']
  $noWrap: TextProps['noWrap']
  $align: TextProps['align']
  $transform: TextProps['transform']
  $withIcon: TextProps['withIcon']
}

const StyledSpan = styled.span<StyledSpanProps>`
  ${({$size, $fontStyle, $weight, lineHeight, withResponsive}) => $size && css`
    ${typography.generateTypography({size: $size, fontStyle: $fontStyle, weight: $weight, lineHeight, withResponsive})}
  `}
  ${({theme, $color}) => $color && css`
    color: ${theme.colors[$color]};
    fill: ${theme.colors[$color]};
  `}
  ${({$noWrap}) => $noWrap && css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}
  ${({$align}) => $align && css`
    text-align: ${$align};
  `}
  ${({$transform}) => $transform && css`
    text-transform: ${$transform};
  `}
  ${({$withIcon}) => $withIcon && css`
    display: flex;
    align-items: center;
  `}
`

const Text: FC<SpanProps & TextProps> = ({
  textStyle,
  component = 'span',
  color = 'text',
  size,
  noWrap,
  align,
  withIcon,
  transform,
  fontStyle,
  weight,
  ...props
}) => {
  invariant(!(textStyle && size), 'Text: Cannot use size and textStyle props at the same time')
  const styleProps = textStyle ? stylesToProps[textStyle] : undefined

  return (
    <StyledSpan
        as={component}
        {...omit(styleProps, ['size', 'transform', 'weight', 'fontStyle'])}
        $color={color}
        $size={size || styleProps?.size}
        $noWrap={noWrap}
        $align={align}
        $withIcon={withIcon}
        $fontStyle={fontStyle ? fontStyle : styleProps && 'fontStyle' in styleProps ? styleProps.fontStyle : fontStyle}
        $transform={transform ? transform : styleProps && 'transform' in styleProps ? styleProps.transform : transform}
        $weight={weight ? weight : styleProps && 'weight' in styleProps ? styleProps.weight : weight}
        {...props}
    />
  )
}

export default Text
