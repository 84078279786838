import {isNil} from 'lodash-es'


type Storage = WindowLocalStorage['localStorage'] | WindowSessionStorage['sessionStorage']

export const get = (storage: Storage, key: string) => {
  const val = storage.getItem(key)
  if (val === null) return undefined
  try {
    return JSON.parse(val)
  } catch (e) {
    storage.removeItem(key) // Prevent permanently broken app
    throw e // But still throw as this is unhandled exception
  }
}

export const set = <Value>(storage: Storage, key: string, value: Value) => {
  if (isNil(value)) return storage.removeItem(key)
  return storage.setItem(key, JSON.stringify(value))
}
