export const INSURANCE_ALLIANZ = 'allianz'
export const INSURANCE_CSOB = 'csob'
export const INSURANCE_GENERALI = 'generali'
export const INSURANCE_GENERTEL = 'genertel'
export const INSURANCE_KOMUNALNA = 'komunalna'
export const INSURANCE_KOOPERATIVA = 'kooperativa'
export const INSURANCE_UNION = 'union'
export const INSURANCE_UNIQA = 'uniqa'
export const INSURANCE_WUSTENROT = 'wustenrot'
export const ALL_INSURANCES = [
  INSURANCE_ALLIANZ,
  INSURANCE_CSOB,
  INSURANCE_GENERALI,
  INSURANCE_GENERTEL,
  INSURANCE_KOMUNALNA,
  INSURANCE_KOOPERATIVA,
  INSURANCE_UNION,
  INSURANCE_UNIQA,
  INSURANCE_WUSTENROT,
] as const

export type Insurance = typeof ALL_INSURANCES[number]
