export const ADDON_ANIMAL = 'animal'
export const ADDON_ASSISTANCE = 'assistance'
export const ADDON_ELEMENT = 'element'
export const ADDON_GLASS = 'glass'
export const ADDON_INJURY = 'injury'
export const ADDON_LIABILITY = 'liability'
export const ADDON_THEFT = 'theft'
export const ALL_ADDONS = [
  ADDON_ANIMAL,
  ADDON_ASSISTANCE,
  ADDON_ELEMENT,
  ADDON_GLASS,
  ADDON_INJURY,
  ADDON_LIABILITY,
  ADDON_THEFT,
] as const

export type Addons = typeof ALL_ADDONS[number]

export const ANIMAL_LIMIT_TYPE_2500 = 'animal_2500' as const
export const ANIMAL_LIMIT_TYPE_5000 = 'animal_5000' as const
export const ANIMAL_LIMIT_TYPE_7500 = 'animal_7500' as const
export const ANIMAL_LIMIT_TYPE = {
  [ANIMAL_LIMIT_TYPE_2500]: 'ANIMAL_2500',
  [ANIMAL_LIMIT_TYPE_5000]: 'ANIMAL_5000',
  [ANIMAL_LIMIT_TYPE_7500]: 'ANIMAL_7500',
}
export const ANIMAL_LIMIT_TYPE_DEFAULT = ANIMAL_LIMIT_TYPE_2500

export const ASSISTANCE_TYPE_BASIC = 'basic' as const
export const ASSISTANCE_TYPE_PREMIUM = 'premium' as const
export const ASSISTANCE_TYPE = {
  [ASSISTANCE_TYPE_BASIC]: 'ASSIST_BASIC',
  [ASSISTANCE_TYPE_PREMIUM]: 'ASSIST_PREMIUM',
}
export const ASSISTANCE_TYPE_DEFAULT = ASSISTANCE_TYPE_BASIC

export const GLASS_LIMIT_TYPE_200 = 'glass_200' as const
export const GLASS_LIMIT_TYPE_300 = 'glass_300' as const
export const GLASS_LIMIT_TYPE_550 = 'glass_550' as const
export const GLASS_LIMIT_TYPE = {
  [GLASS_LIMIT_TYPE_200]: 'GLASS_200',
  [GLASS_LIMIT_TYPE_300]: 'GLASS_300',
  [GLASS_LIMIT_TYPE_550]: 'GLASS_550',
}
export const GLASS_LIMIT_TYPE_DEFAULT = GLASS_LIMIT_TYPE_200

export const INJURY_TYPE_DRIVER = 'injury_driver' as const
export const INJURY_TYPE_CREW = 'injury_crew' as const
export const INJURY_TYPE = {
  [INJURY_TYPE_DRIVER]: 'INJURY_DRIVER',
  [INJURY_TYPE_CREW]: 'INJURY_CREW',
}
export const INJURY_TYPE_DEFAULT = INJURY_TYPE_DRIVER
