import type {LegalForm} from '../../constants/legalFormTypes'
import {PRIVATE_PERSON} from '../../constants/legalFormTypes'
import {bornNumberMatchesBirthday} from '../utils'


export const validateBornNumber = (legalForm: LegalForm, birthday?: string, bornNumber?: string) => {
  if (legalForm === PRIVATE_PERSON && birthday && bornNumber && !bornNumberMatchesBirthday(bornNumber, birthday)) {
    return 'Rodné číslo sa nezhoduje s dátumom narodenia.'
  }
}
