import {noop} from 'lodash-es'
import type {FC, ReactNode} from 'react'
import {createContext, useMemo, useState, useContext} from 'react'
import t from '../translations'


type Options = {
  buttonText?: string
  onClose?: () => void
}

type AlertContextType = {
  error: boolean | string
  setError: (error: boolean | string) => void
  options?: Options
  setOptions: (options?: Options) => void
}

const AlertContext = createContext<AlertContextType>({
  error: false,
  setError: noop,
  options: {buttonText: t('form.errorButton')},
  setOptions: noop,
})

type AlertProviderProps = {
  children: ReactNode
}

export const AlertProvider: FC<AlertProviderProps> = ({children}) => {
  const [error, setError] = useState<boolean | string>(false)
  const [options, setOptions] = useState<Options | undefined>({buttonText: t('form.errorButton')})

  const memoizedValue = useMemo(() => ({
    error,
    setError,
    options,
    setOptions,
  }), [error, options])

  return (
    <AlertContext.Provider value={memoizedValue}>
      {children}
    </AlertContext.Provider>
  )
}

const defaultOptions = {
  buttonText: t('form.errorButton'),
}

const useAlert = () => {
  const {error, setError, options, setOptions} = useContext<AlertContextType>(AlertContext)

  const showAlert = (message: string, options: Options | undefined = defaultOptions) => {
    setError(message)
    setOptions(options)
  }

  return {showAlert, error, setError, options, setOptions}
}

export default useAlert
