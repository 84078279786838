export const TRANSMISSION_A = 'a'
export const TRANSMISSION_A1 = 'a1'
export const TRANSMISSION_A2 = 'a2'
export const TRANSMISSION_A3 = 'a3'
export const TRANSMISSION_A4 = 'a4'
export const TRANSMISSION_A5 = 'a5'
export const TRANSMISSION_A6 = 'a6'
export const TRANSMISSION_A7 = 'a7'
export const TRANSMISSION_A8 = 'a8'
export const TRANSMISSION_A9 = 'a9'
export const TRANSMISSION_A10 = 'a10'
export const TRANSMISSION_A12 = 'a12'
export const TRANSMISSION_A16 = 'a16'
export const TRANSMISSION_AV = 'av'
export const TRANSMISSION_M = 'm'
export const TRANSMISSION_M2 = 'm2'
export const TRANSMISSION_M4 = 'm4'
export const TRANSMISSION_M5 = 'm5'
export const TRANSMISSION_M6 = 'm6'
export const TRANSMISSION_M7 = 'm7'
export const TRANSMISSION_M8 = 'm8'
export const TRANSMISSION_M9 = 'm9'
export const TRANSMISSION_M12 = 'm12'
export const TRANSMISSION_M14 = 'm14'
export const TRANSMISSION_M16 = 'm16'
export const TRANSMISSION_R = 'r'
export const TRANSMISSION_R5 = 'r5'
export const TRANSMISSION_R6 = 'r6'
export const TRANSMISSION_R7 = 'r7'
export const TRANSMISSION_R8 = 'r8'
export const TRANSMISSION_R12 = 'r12'
export const ALL_TRANSMISSIONS = [
  TRANSMISSION_A,
  TRANSMISSION_A1,
  TRANSMISSION_A2,
  TRANSMISSION_A3,
  TRANSMISSION_A4,
  TRANSMISSION_A5,
  TRANSMISSION_A6,
  TRANSMISSION_A7,
  TRANSMISSION_A8,
  TRANSMISSION_A9,
  TRANSMISSION_A10,
  TRANSMISSION_A12,
  TRANSMISSION_A16,
  TRANSMISSION_AV,
  TRANSMISSION_M,
  TRANSMISSION_M2,
  TRANSMISSION_M4,
  TRANSMISSION_M5,
  TRANSMISSION_M6,
  TRANSMISSION_M7,
  TRANSMISSION_M8,
  TRANSMISSION_M9,
  TRANSMISSION_M12,
  TRANSMISSION_M14,
  TRANSMISSION_M16,
  TRANSMISSION_R,
  TRANSMISSION_R5,
  TRANSMISSION_R6,
  TRANSMISSION_R7,
  TRANSMISSION_R8,
  TRANSMISSION_R12,
] as const
