export const FUEL_BENZIN = 'benzin'
export const FUEL_NAFTA = 'nafta'
export const FUEL_BENZIN_ELEKTRO = 'benzin_elektro'
export const FUEL_BENZIN_ELEKTRO_PLUG_IN = 'benzin_elektro_plug_in'
export const FUEL_NAFTA_ELEKTRO = 'nafta_elektro'
export const FUEL_NAFTA_ELEKTRO_PLUG_IN = 'nafta_elektro_plug_in'
export const FUEL_ELEKTRO = 'elektro'
export const FUEL_BENZIN_GAS = 'benzin_gas'
export const FUEL_GAS = 'gas'
export const ALL_FUELS = [
  FUEL_BENZIN,
  FUEL_NAFTA,
  FUEL_BENZIN_ELEKTRO,
  FUEL_BENZIN_ELEKTRO_PLUG_IN,
  FUEL_NAFTA_ELEKTRO,
  FUEL_NAFTA_ELEKTRO_PLUG_IN,
  FUEL_ELEKTRO,
] as const

export type Fuel = typeof ALL_FUELS[number]

export const ALL_EXTENDED_FUELS = [
  ...ALL_FUELS,
  FUEL_BENZIN_GAS,
  FUEL_GAS,
] as const
