import type {ComponentProps, FC, ReactNode} from 'react'
import {Suspense} from 'react'
import useIsClient from '../../../../web/src/hooks/useIsClient'


type SuspenseProps = ComponentProps<typeof Suspense>

type SSRSuspenseProps = {
  // Suspense requires fallback
  fallback: ReactNode
}

const SSRSuspense: FC<SuspenseProps & SSRSuspenseProps> = ({fallback, ...props}) => {
  const isClient = useIsClient()

  if (!isClient) return fallback

  return <Suspense fallback={fallback} {...props} />
}

export default SSRSuspense
