import {isNumber, isString, keys} from 'lodash-es'
import type {ElementType, FC, SVGProps} from 'react'
import styled, {css} from 'styled-components'
import theme, {px2rem} from '../../theme/theme'
import type {FontSizes} from '../../theme/theme'


type SvgProps = SVGProps<SVGSVGElement>

type IconProps = {
  icon?: ElementType
  noFill?: boolean
  title?: string
  size?: FontSizes | number
}

type StyledSvgProps = {
  $size: IconProps['size']
  $noFill: IconProps['noFill']
}

const StyledSvg = styled.svg<StyledSvgProps>`
  display: inline-block;
  width: auto;
  /* stylelint-disable */
  height: ${({theme, $size}) => isNumber($size) ? px2rem($size) : isString($size) ? theme.fonts.size[$size] : 'auto'};
  /* stylelint-enable  */

  ${({$noFill}) => !$noFill && css`
    path,
    g {
      fill: inherit;
    }
  `}
`

const Icon: FC<SvgProps & IconProps> = ({icon, noFill = false, size, ...props}) => (
  <StyledSvg as={icon} $noFill={noFill} $size={size} {...props} />
)

export const sizeKeys = keys(theme.fonts.size)

export default Icon
