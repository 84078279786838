export const TELEPHONE_CODE_93 = '+93' as const
export const TELEPHONE_CODE_355 = '+355' as const
export const TELEPHONE_CODE_213 = '+213' as const
export const TELEPHONE_CODE_684 = '+684' as const
export const TELEPHONE_CODE_376 = '+376' as const
export const TELEPHONE_CODE_244 = '+244' as const
export const TELEPHONE_CODE_1264 = '+1264' as const
export const TELEPHONE_CODE_1268 = '+1268' as const
export const TELEPHONE_CODE_54 = '+54' as const
export const TELEPHONE_CODE_374 = '+374' as const
export const TELEPHONE_CODE_297 = '+297' as const
export const TELEPHONE_CODE_247 = '+247' as const
export const TELEPHONE_CODE_61 = '+61' as const
export const TELEPHONE_CODE_672 = '+672' as const
export const TELEPHONE_CODE_43 = '+43' as const
export const TELEPHONE_CODE_994 = '+994' as const
export const TELEPHONE_CODE_1242 = '+1242' as const
export const TELEPHONE_CODE_973 = '+973' as const
export const TELEPHONE_CODE_880 = '+880' as const
export const TELEPHONE_CODE_1246 = '+1246' as const
export const TELEPHONE_CODE_375 = '+375' as const
export const TELEPHONE_CODE_32 = '+32' as const
export const TELEPHONE_CODE_501 = '+501' as const
export const TELEPHONE_CODE_229 = '+229' as const
export const TELEPHONE_CODE_1441 = '+1441' as const
export const TELEPHONE_CODE_975 = '+975' as const
export const TELEPHONE_CODE_591 = '+591' as const
export const TELEPHONE_CODE_387 = '+387' as const
export const TELEPHONE_CODE_267 = '+267' as const
export const TELEPHONE_CODE_55 = '+55' as const
export const TELEPHONE_CODE_1284 = '+1284' as const
export const TELEPHONE_CODE_673 = '+673' as const
export const TELEPHONE_CODE_359 = '+359' as const
export const TELEPHONE_CODE_226 = '+226' as const
export const TELEPHONE_CODE_257 = '+257' as const
export const TELEPHONE_CODE_855 = '+855' as const
export const TELEPHONE_CODE_237 = '+237' as const
export const TELEPHONE_CODE_1 = '+1' as const
export const TELEPHONE_CODE_238 = '+238' as const
export const TELEPHONE_CODE_1345 = '+1345' as const
export const TELEPHONE_CODE_236 = '+236' as const
export const TELEPHONE_CODE_235 = '+235' as const
export const TELEPHONE_CODE_56 = '+56' as const
export const TELEPHONE_CODE_86 = '+86' as const
export const TELEPHONE_CODE_57 = '+57' as const
export const TELEPHONE_CODE_269 = '+269' as const
export const TELEPHONE_CODE_242 = '+242' as const
export const TELEPHONE_CODE_243 = '+243' as const
export const TELEPHONE_CODE_682 = '+682' as const
export const TELEPHONE_CODE_506 = '+506' as const
export const TELEPHONE_CODE_225 = '+225' as const
export const TELEPHONE_CODE_385 = '+385' as const
export const TELEPHONE_CODE_53 = '+53' as const
export const TELEPHONE_CODE_5399 = '+5399' as const
export const TELEPHONE_CODE_357 = '+357' as const
export const TELEPHONE_CODE_420 = '+420' as const
export const TELEPHONE_CODE_45 = '+45' as const
export const TELEPHONE_CODE_246 = '+246' as const
export const TELEPHONE_CODE_253 = '+253' as const
export const TELEPHONE_CODE_1767 = '+1767' as const
export const TELEPHONE_CODE_1809 = '+1809' as const
export const TELEPHONE_CODE_670 = '+670' as const
export const TELEPHONE_CODE_593 = '+593' as const
export const TELEPHONE_CODE_20 = '+20' as const
export const TELEPHONE_CODE_503 = '+503' as const
export const TELEPHONE_CODE_88213 = '+88213' as const
export const TELEPHONE_CODE_240 = '+240' as const
export const TELEPHONE_CODE_291 = '+291' as const
export const TELEPHONE_CODE_372 = '+372' as const
export const TELEPHONE_CODE_251 = '+251' as const
export const TELEPHONE_CODE_500 = '+500' as const
export const TELEPHONE_CODE_298 = '+298' as const
export const TELEPHONE_CODE_679 = '+679' as const
export const TELEPHONE_CODE_358 = '+358' as const
export const TELEPHONE_CODE_33 = '+33' as const
export const TELEPHONE_CODE_594 = '+594' as const
export const TELEPHONE_CODE_689 = '+689' as const
export const TELEPHONE_CODE_241 = '+241' as const
export const TELEPHONE_CODE_220 = '+220' as const
export const TELEPHONE_CODE_995 = '+995' as const
export const TELEPHONE_CODE_49 = '+49' as const
export const TELEPHONE_CODE_233 = '+233' as const
export const TELEPHONE_CODE_350 = '+350' as const
export const TELEPHONE_CODE_30 = '+30' as const
export const TELEPHONE_CODE_299 = '+299' as const
export const TELEPHONE_CODE_1473 = '+1473' as const
export const TELEPHONE_CODE_590 = '+590' as const
export const TELEPHONE_CODE_1671 = '+1671' as const
export const TELEPHONE_CODE_502 = '+502' as const
export const TELEPHONE_CODE_245 = '+245' as const
export const TELEPHONE_CODE_224 = '+224' as const
export const TELEPHONE_CODE_592 = '+592' as const
export const TELEPHONE_CODE_509 = '+509' as const
export const TELEPHONE_CODE_504 = '+504' as const
export const TELEPHONE_CODE_852 = '+852' as const
export const TELEPHONE_CODE_36 = '+36' as const
export const TELEPHONE_CODE_91 = '+91' as const
export const TELEPHONE_CODE_62 = '+62' as const
export const TELEPHONE_CODE_871 = '+871' as const
export const TELEPHONE_CODE_874 = '+874' as const
export const TELEPHONE_CODE_873 = '+873' as const
export const TELEPHONE_CODE_872 = '+872' as const
export const TELEPHONE_CODE_98 = '+98' as const
export const TELEPHONE_CODE_964 = '+964' as const
export const TELEPHONE_CODE_353 = '+353' as const
export const TELEPHONE_CODE_8816 = '+8816' as const
export const TELEPHONE_CODE_972 = '+972' as const
export const TELEPHONE_CODE_39 = '+39' as const
export const TELEPHONE_CODE_1876 = '+1876' as const
export const TELEPHONE_CODE_81 = '+81' as const
export const TELEPHONE_CODE_962 = '+962' as const
export const TELEPHONE_CODE_7 = '+7' as const
export const TELEPHONE_CODE_254 = '+254' as const
export const TELEPHONE_CODE_686 = '+686' as const
export const TELEPHONE_CODE_850 = '+850' as const
export const TELEPHONE_CODE_82 = '+82' as const
export const TELEPHONE_CODE_965 = '+965' as const
export const TELEPHONE_CODE_996 = '+996' as const
export const TELEPHONE_CODE_856 = '+856' as const
export const TELEPHONE_CODE_371 = '+371' as const
export const TELEPHONE_CODE_961 = '+961' as const
export const TELEPHONE_CODE_266 = '+266' as const
export const TELEPHONE_CODE_231 = '+231' as const
export const TELEPHONE_CODE_218 = '+218' as const
export const TELEPHONE_CODE_423 = '+423' as const
export const TELEPHONE_CODE_370 = '+370' as const
export const TELEPHONE_CODE_352 = '+352' as const
export const TELEPHONE_CODE_853 = '+853' as const
export const TELEPHONE_CODE_389 = '+389' as const
export const TELEPHONE_CODE_261 = '+261' as const
export const TELEPHONE_CODE_265 = '+265' as const
export const TELEPHONE_CODE_60 = '+60' as const
export const TELEPHONE_CODE_960 = '+960' as const
export const TELEPHONE_CODE_223 = '+223' as const
export const TELEPHONE_CODE_356 = '+356' as const
export const TELEPHONE_CODE_692 = '+692' as const
export const TELEPHONE_CODE_596 = '+596' as const
export const TELEPHONE_CODE_222 = '+222' as const
export const TELEPHONE_CODE_230 = '+230' as const
export const TELEPHONE_CODE_52 = '+52' as const
export const TELEPHONE_CODE_691 = '+691' as const
export const TELEPHONE_CODE_373 = '+373' as const
export const TELEPHONE_CODE_377 = '+377' as const
export const TELEPHONE_CODE_976 = '+976' as const
export const TELEPHONE_CODE_1664 = '+1664' as const
export const TELEPHONE_CODE_212 = '+212' as const
export const TELEPHONE_CODE_258 = '+258' as const
export const TELEPHONE_CODE_95 = '+95' as const
export const TELEPHONE_CODE_264 = '+264' as const
export const TELEPHONE_CODE_674 = '+674' as const
export const TELEPHONE_CODE_977 = '+977' as const
export const TELEPHONE_CODE_31 = '+31' as const
export const TELEPHONE_CODE_599 = '+599' as const
export const TELEPHONE_CODE_687 = '+687' as const
export const TELEPHONE_CODE_64 = '+64' as const
export const TELEPHONE_CODE_505 = '+505' as const
export const TELEPHONE_CODE_227 = '+227' as const
export const TELEPHONE_CODE_234 = '+234' as const
export const TELEPHONE_CODE_683 = '+683' as const
export const TELEPHONE_CODE_1670 = '+1670' as const
export const TELEPHONE_CODE_47 = '+47' as const
export const TELEPHONE_CODE_968 = '+968' as const
export const TELEPHONE_CODE_92 = '+92' as const
export const TELEPHONE_CODE_680 = '+680' as const
export const TELEPHONE_CODE_507 = '+507' as const
export const TELEPHONE_CODE_675 = '+675' as const
export const TELEPHONE_CODE_595 = '+595' as const
export const TELEPHONE_CODE_51 = '+51' as const
export const TELEPHONE_CODE_63 = '+63' as const
export const TELEPHONE_CODE_48 = '+48' as const
export const TELEPHONE_CODE_351 = '+351' as const
export const TELEPHONE_CODE_1939 = '+1939' as const
export const TELEPHONE_CODE_974 = '+974' as const
export const TELEPHONE_CODE_262 = '+262' as const
export const TELEPHONE_CODE_40 = '+40' as const
export const TELEPHONE_CODE_250 = '+250' as const
export const TELEPHONE_CODE_290 = '+290' as const
export const TELEPHONE_CODE_1869 = '+1869' as const
export const TELEPHONE_CODE_1758 = '+1758' as const
export const TELEPHONE_CODE_508 = '+508' as const
export const TELEPHONE_CODE_1784 = '+1784' as const
export const TELEPHONE_CODE_378 = '+378' as const
export const TELEPHONE_CODE_239 = '+239' as const
export const TELEPHONE_CODE_966 = '+966' as const
export const TELEPHONE_CODE_221 = '+221' as const
export const TELEPHONE_CODE_381 = '+381' as const
export const TELEPHONE_CODE_248 = '+248' as const
export const TELEPHONE_CODE_232 = '+232' as const
export const TELEPHONE_CODE_65 = '+65' as const
export const TELEPHONE_CODE_421 = '+421' as const
export const TELEPHONE_CODE_386 = '+386' as const
export const TELEPHONE_CODE_677 = '+677' as const
export const TELEPHONE_CODE_252 = '+252' as const
export const TELEPHONE_CODE_27 = '+27' as const
export const TELEPHONE_CODE_34 = '+34' as const
export const TELEPHONE_CODE_94 = '+94' as const
export const TELEPHONE_CODE_249 = '+249' as const
export const TELEPHONE_CODE_597 = '+597' as const
export const TELEPHONE_CODE_268 = '+268' as const
export const TELEPHONE_CODE_46 = '+46' as const
export const TELEPHONE_CODE_41 = '+41' as const
export const TELEPHONE_CODE_963 = '+963' as const
export const TELEPHONE_CODE_886 = '+886' as const
export const TELEPHONE_CODE_992 = '+992' as const
export const TELEPHONE_CODE_255 = '+255' as const
export const TELEPHONE_CODE_66 = '+66' as const
export const TELEPHONE_CODE_228 = '+228' as const
export const TELEPHONE_CODE_690 = '+690' as const
export const TELEPHONE_CODE_676 = '+676' as const
export const TELEPHONE_CODE_1868 = '+1868' as const
export const TELEPHONE_CODE_216 = '+216' as const
export const TELEPHONE_CODE_90 = '+90' as const
export const TELEPHONE_CODE_993 = '+993' as const
export const TELEPHONE_CODE_1649 = '+1649' as const
export const TELEPHONE_CODE_688 = '+688' as const
export const TELEPHONE_CODE_256 = '+256' as const
export const TELEPHONE_CODE_380 = '+380' as const
export const TELEPHONE_CODE_971 = '+971' as const
export const TELEPHONE_CODE_44 = '+44' as const
export const TELEPHONE_CODE_1340 = '+1340' as const
export const TELEPHONE_CODE_598 = '+598' as const
export const TELEPHONE_CODE_998 = '+998' as const
export const TELEPHONE_CODE_678 = '+678' as const
export const TELEPHONE_CODE_58 = '+58' as const
export const TELEPHONE_CODE_84 = '+84' as const
export const TELEPHONE_CODE_681 = '+681' as const
export const TELEPHONE_CODE_685 = '+685' as const
export const TELEPHONE_CODE_967 = '+967' as const
export const TELEPHONE_CODE_260 = '+260' as const
export const TELEPHONE_CODE_263 = '+263' as const
export const TELEPHONE_CODE_354 = '+354' as const
export const TELEPHONE_CODE_8817 = '+8817' as const
export const TELEPHONE_CODE_1787 = '+1787' as const
export const TELEPHONE_CODE_0 = '0' as const
export const TELEPHONE_CODE_379 = '+379' as const
export const TELEPHONE_CODE_211 = '+211' as const
export const TELEPHONE_CODE_382 = '+382' as const
export const TELEPHONE_CODE_383 = '+383' as const
export const TELEPHONE_CODE_970 = '+970' as const
export const ALL_TELEPHONE_CODES = [
  TELEPHONE_CODE_93,
  TELEPHONE_CODE_355,
  TELEPHONE_CODE_213,
  TELEPHONE_CODE_684,
  TELEPHONE_CODE_376,
  TELEPHONE_CODE_244,
  TELEPHONE_CODE_1264,
  TELEPHONE_CODE_1268,
  TELEPHONE_CODE_54,
  TELEPHONE_CODE_374,
  TELEPHONE_CODE_297,
  TELEPHONE_CODE_247,
  TELEPHONE_CODE_61,
  TELEPHONE_CODE_672,
  TELEPHONE_CODE_43,
  TELEPHONE_CODE_994,
  TELEPHONE_CODE_1242,
  TELEPHONE_CODE_973,
  TELEPHONE_CODE_880,
  TELEPHONE_CODE_1246,
  TELEPHONE_CODE_375,
  TELEPHONE_CODE_32,
  TELEPHONE_CODE_501,
  TELEPHONE_CODE_229,
  TELEPHONE_CODE_1441,
  TELEPHONE_CODE_975,
  TELEPHONE_CODE_591,
  TELEPHONE_CODE_387,
  TELEPHONE_CODE_267,
  TELEPHONE_CODE_55,
  TELEPHONE_CODE_1284,
  TELEPHONE_CODE_673,
  TELEPHONE_CODE_359,
  TELEPHONE_CODE_226,
  TELEPHONE_CODE_257,
  TELEPHONE_CODE_855,
  TELEPHONE_CODE_237,
  TELEPHONE_CODE_1,
  TELEPHONE_CODE_238,
  TELEPHONE_CODE_1345,
  TELEPHONE_CODE_236,
  TELEPHONE_CODE_235,
  TELEPHONE_CODE_56,
  TELEPHONE_CODE_86,
  TELEPHONE_CODE_57,
  TELEPHONE_CODE_269,
  TELEPHONE_CODE_242,
  TELEPHONE_CODE_243,
  TELEPHONE_CODE_682,
  TELEPHONE_CODE_506,
  TELEPHONE_CODE_225,
  TELEPHONE_CODE_385,
  TELEPHONE_CODE_53,
  TELEPHONE_CODE_5399,
  TELEPHONE_CODE_357,
  TELEPHONE_CODE_420,
  TELEPHONE_CODE_45,
  TELEPHONE_CODE_246,
  TELEPHONE_CODE_253,
  TELEPHONE_CODE_1767,
  TELEPHONE_CODE_1809,
  TELEPHONE_CODE_670,
  TELEPHONE_CODE_593,
  TELEPHONE_CODE_20,
  TELEPHONE_CODE_503,
  TELEPHONE_CODE_88213,
  TELEPHONE_CODE_240,
  TELEPHONE_CODE_291,
  TELEPHONE_CODE_372,
  TELEPHONE_CODE_251,
  TELEPHONE_CODE_500,
  TELEPHONE_CODE_298,
  TELEPHONE_CODE_679,
  TELEPHONE_CODE_358,
  TELEPHONE_CODE_33,
  TELEPHONE_CODE_594,
  TELEPHONE_CODE_689,
  TELEPHONE_CODE_241,
  TELEPHONE_CODE_220,
  TELEPHONE_CODE_995,
  TELEPHONE_CODE_49,
  TELEPHONE_CODE_233,
  TELEPHONE_CODE_350,
  TELEPHONE_CODE_30,
  TELEPHONE_CODE_299,
  TELEPHONE_CODE_1473,
  TELEPHONE_CODE_590,
  TELEPHONE_CODE_1671,
  TELEPHONE_CODE_502,
  TELEPHONE_CODE_245,
  TELEPHONE_CODE_224,
  TELEPHONE_CODE_592,
  TELEPHONE_CODE_509,
  TELEPHONE_CODE_504,
  TELEPHONE_CODE_852,
  TELEPHONE_CODE_36,
  TELEPHONE_CODE_91,
  TELEPHONE_CODE_62,
  TELEPHONE_CODE_871,
  TELEPHONE_CODE_874,
  TELEPHONE_CODE_873,
  TELEPHONE_CODE_872,
  TELEPHONE_CODE_98,
  TELEPHONE_CODE_964,
  TELEPHONE_CODE_353,
  TELEPHONE_CODE_8816,
  TELEPHONE_CODE_972,
  TELEPHONE_CODE_39,
  TELEPHONE_CODE_1876,
  TELEPHONE_CODE_81,
  TELEPHONE_CODE_962,
  TELEPHONE_CODE_7,
  TELEPHONE_CODE_254,
  TELEPHONE_CODE_686,
  TELEPHONE_CODE_850,
  TELEPHONE_CODE_82,
  TELEPHONE_CODE_965,
  TELEPHONE_CODE_996,
  TELEPHONE_CODE_856,
  TELEPHONE_CODE_371,
  TELEPHONE_CODE_961,
  TELEPHONE_CODE_266,
  TELEPHONE_CODE_231,
  TELEPHONE_CODE_218,
  TELEPHONE_CODE_423,
  TELEPHONE_CODE_370,
  TELEPHONE_CODE_352,
  TELEPHONE_CODE_853,
  TELEPHONE_CODE_389,
  TELEPHONE_CODE_261,
  TELEPHONE_CODE_265,
  TELEPHONE_CODE_60,
  TELEPHONE_CODE_960,
  TELEPHONE_CODE_223,
  TELEPHONE_CODE_356,
  TELEPHONE_CODE_692,
  TELEPHONE_CODE_596,
  TELEPHONE_CODE_222,
  TELEPHONE_CODE_230,
  TELEPHONE_CODE_52,
  TELEPHONE_CODE_691,
  TELEPHONE_CODE_373,
  TELEPHONE_CODE_377,
  TELEPHONE_CODE_976,
  TELEPHONE_CODE_1664,
  TELEPHONE_CODE_212,
  TELEPHONE_CODE_258,
  TELEPHONE_CODE_95,
  TELEPHONE_CODE_264,
  TELEPHONE_CODE_674,
  TELEPHONE_CODE_977,
  TELEPHONE_CODE_31,
  TELEPHONE_CODE_599,
  TELEPHONE_CODE_687,
  TELEPHONE_CODE_64,
  TELEPHONE_CODE_505,
  TELEPHONE_CODE_227,
  TELEPHONE_CODE_234,
  TELEPHONE_CODE_683,
  TELEPHONE_CODE_1670,
  TELEPHONE_CODE_47,
  TELEPHONE_CODE_968,
  TELEPHONE_CODE_92,
  TELEPHONE_CODE_680,
  TELEPHONE_CODE_507,
  TELEPHONE_CODE_675,
  TELEPHONE_CODE_595,
  TELEPHONE_CODE_51,
  TELEPHONE_CODE_63,
  TELEPHONE_CODE_48,
  TELEPHONE_CODE_351,
  TELEPHONE_CODE_1939,
  TELEPHONE_CODE_974,
  TELEPHONE_CODE_262,
  TELEPHONE_CODE_40,
  TELEPHONE_CODE_250,
  TELEPHONE_CODE_290,
  TELEPHONE_CODE_1869,
  TELEPHONE_CODE_1758,
  TELEPHONE_CODE_508,
  TELEPHONE_CODE_1784,
  TELEPHONE_CODE_378,
  TELEPHONE_CODE_239,
  TELEPHONE_CODE_966,
  TELEPHONE_CODE_221,
  TELEPHONE_CODE_381,
  TELEPHONE_CODE_248,
  TELEPHONE_CODE_232,
  TELEPHONE_CODE_65,
  TELEPHONE_CODE_421,
  TELEPHONE_CODE_386,
  TELEPHONE_CODE_677,
  TELEPHONE_CODE_252,
  TELEPHONE_CODE_27,
  TELEPHONE_CODE_34,
  TELEPHONE_CODE_94,
  TELEPHONE_CODE_249,
  TELEPHONE_CODE_597,
  TELEPHONE_CODE_268,
  TELEPHONE_CODE_46,
  TELEPHONE_CODE_41,
  TELEPHONE_CODE_963,
  TELEPHONE_CODE_886,
  TELEPHONE_CODE_992,
  TELEPHONE_CODE_255,
  TELEPHONE_CODE_66,
  TELEPHONE_CODE_228,
  TELEPHONE_CODE_690,
  TELEPHONE_CODE_676,
  TELEPHONE_CODE_1868,
  TELEPHONE_CODE_216,
  TELEPHONE_CODE_90,
  TELEPHONE_CODE_993,
  TELEPHONE_CODE_1649,
  TELEPHONE_CODE_688,
  TELEPHONE_CODE_256,
  TELEPHONE_CODE_380,
  TELEPHONE_CODE_971,
  TELEPHONE_CODE_44,
  TELEPHONE_CODE_1340,
  TELEPHONE_CODE_598,
  TELEPHONE_CODE_998,
  TELEPHONE_CODE_678,
  TELEPHONE_CODE_58,
  TELEPHONE_CODE_84,
  TELEPHONE_CODE_681,
  TELEPHONE_CODE_685,
  TELEPHONE_CODE_967,
  TELEPHONE_CODE_260,
  TELEPHONE_CODE_263,
  TELEPHONE_CODE_354,
  TELEPHONE_CODE_8817,
  TELEPHONE_CODE_1787,
  TELEPHONE_CODE_0,
  TELEPHONE_CODE_379,
  TELEPHONE_CODE_211,
  TELEPHONE_CODE_382,
  TELEPHONE_CODE_383,
  TELEPHONE_CODE_970,
] as const

export const DEFAULT_TELEPHONE_CODE = TELEPHONE_CODE_421
