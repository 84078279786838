import {add, isSameDay} from 'date-fns'
import {sum, values, entries, keys, some} from 'lodash'
import {z} from 'zod'
import * as abtests from '../constants/abtests'
import type {AbTest} from '../constants/abtests'


export const COOKIE_NAME = 'abtests'
export const COOKIE_SEPARATOR = ','
export const VARIANT_SEPARATOR = ':'

const envSchema = z
  .string()
  .transform((val) => val.split(';'))
  .pipe(z.enum(abtests.ALL_AB_TESTS).array())

export const getEnabledTests = () => {
  // eslint-disable-next-line no-process-env
  const env = process.env.GATSBY_ABTESTS
  if (!env) return []
  const res = envSchema.safeParse(env)
  if (!res.success) return []
  return res.data
}

export type AbtestCurrent = Partial<Record<AbTest, string>>

const pickRandom = <T extends string>(variants: Record<T, number>) => {
  const total = sum(values(variants))
  const random = Math.random() * total
  let max = 0
  for (const variant of keys(variants) as T[]) { // Generic Record so always true
    max += variants[variant]
    if (random <= max) return variant
  }
  return null // Invariant, but fail gracefully
}

type Test <T extends string, U extends T> = {
  priority: number
  variants: T[]
  pickVariant: (current?: AbtestCurrent) => U | null
}

const testFactory = <T extends string, U extends T>({variants, priority, pickVariant}: Test<T, U>): Test<T, U> => {
  return {
    priority,
    pickVariant,
    variants,
  }
}

const phoneOperatorAvailable = [
  new Date('2023-12-2'),
  new Date('2023-12-3'),
  new Date('2023-12-5'),
  new Date('2023-12-6'),
  new Date('2023-12-8'),
  new Date('2023-12-12'),
  new Date('2023-12-13'),
  new Date('2023-12-15'),
  new Date('2023-12-16'),
  new Date('2023-12-17'),
  new Date('2023-12-19'),
  new Date('2023-12-20'),
  new Date('2023-12-21'),
]

export const conf = {
  [abtests.PHONE_NUMBER]: testFactory({
    priority: 10,
    variants: ['show', 'hide'],
    pickVariant: (picked) => {
      if (picked?.[abtests.PHONE_CALL]) return null
      return pickRandom({show: 50, hide: 50} as const)
    },
  }),
  [abtests.PHONE_CALL]: testFactory({
    priority: 1,
    variants: ['send', 'store'],
    pickVariant: (picked) => {
      if (picked?.[abtests.PHONE_NUMBER]) return null
      // Phone operator date starts and ends 7 hours earlier at 17:00.
      const phoneOperatorDate = add(new Date, {hours: 7})
      if (!some(phoneOperatorAvailable, (availableDate) => isSameDay(availableDate, phoneOperatorDate))) return null
      return pickRandom({send: 50, store: 50} as const)
    },
  }),
  [abtests.BORN_NUMBER]: testFactory({
    priority: 1,
    variants: ['true', 'false'],
    pickVariant: (picked) => {
      if (picked?.[abtests.BORN_NUMBER]) return null
      return pickRandom({true: 50, false: 50} as const)
    },
  }),
} as const

const isValidTest = (test: string): test is keyof typeof conf => keys(conf).includes(test)
export const parseAbtests = (values: string[]) => {
  return values.reduce((acc, curr) => {
    const val = curr.split(VARIANT_SEPARATOR)
    if (!val[1]) return acc
    const [test] = val
    if (!isValidTest(test)) return acc

    const {variants} = conf[test]
    if (!(variants as string[]).includes(val[1])) return acc
    const variant = val[1] as typeof variants[number]
    return {...acc, [test]: variant}
  }, {} as AbtestCurrent)
}

export const formatAbtest = <T extends AbTest>(test: T, variant: (typeof conf)[T]['variants'][number]) => (
  `${test}${VARIANT_SEPARATOR}${variant}`
)
export const formatAbtests = (values: AbtestCurrent) => {
  return entries(values)
    .map(<T extends AbTest>([key, value]: [T, (typeof conf)[T]['variants'][number]]) => formatAbtest(key, value))
}

export const isVariantActive = <T extends AbTest>(
  abtests: AbtestCurrent | undefined, test: T, variant: (typeof conf)[T]['variants'][number],
) => {
  if (!abtests) return false
  return abtests[test] === variant
}
