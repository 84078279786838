export const PAYMENT_FREQUENCY_YEARLY = 'yearly'
export const PAYMENT_FREQUENCY_SEMIANNUALLY = 'semiAnnually'
export const PAYMENT_FREQUENCY_QUARTERLY = 'quarterly'
export const ALL_PAYMENT_FREQUENCIES = [
  PAYMENT_FREQUENCY_YEARLY,
  PAYMENT_FREQUENCY_SEMIANNUALLY,
  PAYMENT_FREQUENCY_QUARTERLY,
] as const

export type PaymentFrequency = typeof ALL_PAYMENT_FREQUENCIES[number]
