export const ADDON_ANIMAL = 'animal'
export const ADDON_ASSISTANCE = 'assistance'
export const ADDON_ELEMENT = 'element'
export const ADDON_GLASS = 'glass'
export const ADDON_THEFT = 'theft'
export const ALL_ADDONS = [
  ADDON_ANIMAL,
  ADDON_ASSISTANCE,
  ADDON_ELEMENT,
  ADDON_GLASS,
  ADDON_THEFT,
] as const

export type Addons = typeof ALL_ADDONS[number]

export const GLASS_COVERAGE_100 = '100'
export const GLASS_COVERAGE_300 = '300'
export const GLASS_COVERAGE_500 = '500'
export const GLASS_COVERAGE_1000 = '1000'
export const GLASS_COVERAGE = {
  [GLASS_COVERAGE_100]: '1',
  [GLASS_COVERAGE_300]: '2',
  [GLASS_COVERAGE_500]: '3',
  [GLASS_COVERAGE_1000]: '4',
}
export const GLASS_COVERAGE_DEFAULT = GLASS_COVERAGE_100
