export const ADDON_ANIMAL = 'animal'
export const ADDON_ASSISTANCE = 'assistance'
export const ADDON_ELEMENT = 'element'
export const ADDON_GLASS = 'glass'
export const ADDON_INJURY = 'injury'
export const ADDON_LUGGAGE = 'luggage'
export const ADDON_THEFT = 'theft'
export const ADDON_VANDALISM = 'vandalism'
export const ALL_ADDONS = [
  ADDON_ANIMAL,
  ADDON_ASSISTANCE,
  ADDON_ELEMENT,
  ADDON_GLASS,
  ADDON_INJURY,
  ADDON_LUGGAGE,
  ADDON_THEFT,
  ADDON_VANDALISM,
] as const

export type Addons = typeof ALL_ADDONS[number]

export const ANIMAL_COVERAGE_2000 = 'animal-2000'
export const ANIMAL_COVERAGE_4000 = 'animal-4000'
export const ANIMAL_COVERAGE_6000 = 'animal-6000'
export const ANIMAL_COVERAGE = {
  [ANIMAL_COVERAGE_2000]: '219',
  [ANIMAL_COVERAGE_4000]: '220',
  [ANIMAL_COVERAGE_6000]: '221',
}
export const ANIMAL_COVERAGE_DEFAULT = ANIMAL_COVERAGE_2000

export const ELEMENT_COVERAGE_2000 = 'element-2000'
export const ELEMENT_COVERAGE_4000 = 'element-4000'
export const ELEMENT_COVERAGE_6000 = 'element-6000'
export const ELEMENT_COVERAGE = {
  [ELEMENT_COVERAGE_2000]: '222',
  [ELEMENT_COVERAGE_4000]: '223',
  [ELEMENT_COVERAGE_6000]: '224',
}
export const ELEMENT_COVERAGE_DEFAULT = ELEMENT_COVERAGE_2000

export const GLASS_COVERAGE_ORIGINAL = 'original'
export const GLASS_COVERAGE_OTHER = 'other'
export const GLASS_COVERAGE = {
  [GLASS_COVERAGE_ORIGINAL]: '0',
  [GLASS_COVERAGE_OTHER]: '1',
}
export const GLASS_COVERAGE_DEFAULT = GLASS_COVERAGE_ORIGINAL

export const INJURY_COVERAGE_DRIVER_10000 = 'injury-driver-10000'
export const INJURY_COVERAGE_DRIVER_20000 = 'injury-driver-20000'
export const INJURY_COVERAGE_DRIVER_40000 = 'injury-driver-40000'
export const INJURY_COVERAGE_DRIVER_200000 = 'injury-driver-200000'
export const INJURY_COVERAGE_ALL_10000 = 'injury-all-10000'
export const INJURY_COVERAGE_ALL_20000 = 'injury-all-20000'
export const INJURY_COVERAGE_ALL_40000 = 'injury-all-40000'
export const INJURY_COVERAGE_ALL_200000 = 'injury-all-200000'
export const INJURY_COVERAGE = {
  [INJURY_COVERAGE_DRIVER_10000]: '195',
  [INJURY_COVERAGE_DRIVER_20000]: '196',
  [INJURY_COVERAGE_DRIVER_40000]: '197',
  [INJURY_COVERAGE_DRIVER_200000]: '198',
  [INJURY_COVERAGE_ALL_10000]: '199',
  [INJURY_COVERAGE_ALL_20000]: '200',
  [INJURY_COVERAGE_ALL_40000]: '201',
  [INJURY_COVERAGE_ALL_200000]: '202',
}
export const INJURY_COVERAGE_DEFAULT = INJURY_COVERAGE_ALL_10000

export const LUGGAGE_COVERAGE_1000 = 'luggage-1000'
export const LUGGAGE_COVERAGE_3000 = 'luggage-3000'
export const LUGGAGE_COVERAGE_6000 = 'luggage-6000'
export const LUGGAGE_COVERAGE = {
  [LUGGAGE_COVERAGE_1000]: '214',
  [LUGGAGE_COVERAGE_3000]: '215',
  [LUGGAGE_COVERAGE_6000]: '256',
}
export const LUGGAGE_COVERAGE_DEFAULT = LUGGAGE_COVERAGE_1000

export const THEFT_COVERAGE_2000 = 'theft-2000'
export const THEFT_COVERAGE_4000 = 'theft-4000'
export const THEFT_COVERAGE_6000 = 'theft-6000'
export const THEFT_COVERAGE = {
  [THEFT_COVERAGE_2000]: '228',
  [THEFT_COVERAGE_4000]: '229',
  [THEFT_COVERAGE_6000]: '230',
}
export const THEFT_COVERAGE_DEFAULT = THEFT_COVERAGE_2000

export const VANDALISM_COVERAGE_2000 = 'vandalism-2000'
export const VANDALISM_COVERAGE_4000 = 'vandalism-4000'
export const VANDALISM_COVERAGE_6000 = 'vandalism-6000'
export const VANDALISM_COVERAGE = {
  [VANDALISM_COVERAGE_2000]: '225',
  [VANDALISM_COVERAGE_4000]: '226',
  [VANDALISM_COVERAGE_6000]: '227',
}
export const VANDALISM_COVERAGE_DEFAULT = VANDALISM_COVERAGE_2000
