import {If} from 'babel-plugin-jsx-control-statements'
import {keys} from 'lodash-es'
import type {ButtonHTMLAttributes, ComponentProps, ElementType, MouseEvent, ReactNode} from 'react'
import styled, {css} from 'styled-components'
import {px2rem} from '../theme/theme'
import {generateTypography} from '../theme/typography'


type ButtonElementProps = ButtonHTMLAttributes<HTMLButtonElement>

type ButtonProps<T extends ElementType> = {
  variant?: keyof typeof applyVariant
  size?: keyof typeof sizes
  fullWidth?: boolean
  disabled?: boolean
  icon?: ReactNode
  onClick?: (e?: MouseEvent) => void
  component?: T
  isLink?: boolean
  children?: ReactNode
} & ComponentProps<T>

type ApplyVariantProps = {
  $disabled: ButtonProps<'button'>['disabled']
}

type StyledButtonProps = {
  $disabled: ButtonProps<'button'>['disabled']
  $size: ButtonProps<'button'>['size']
  $fullWidth: ButtonProps<'button'>['fullWidth']
  $variant: ButtonProps<'button'>['variant']
}

const applyVariant = {
  primary: css<ApplyVariantProps>`
    transition: ${({theme}) => `background-color ${theme.animation}, box-shadow ${theme.animation}`};
    border: ${({theme}) => `2px solid ${theme.colors.textInverted}`};
    background-color: ${({theme}) => theme.colors.primary};
    padding: 0 1.5rem;
    color: ${({theme}) => theme.colors.textInverted};
    ${generateTypography({size: 20, weight: 'bold', withResponsive: false})}
    path,
    g {
      transition: ${({theme}) => `fill ${theme.animation}`};
      fill: ${({theme}) => theme.colors.textInverted};
    }

    ${({$disabled}) => !$disabled && css`
      &:hover,
      &:focus {
        box-shadow: ${({theme}) => `0 ${px2rem(4)} 0 0 ${theme.colors.textInverted}`};
        background-color: ${({theme}) => theme.colors.primary2};
      }
    `}
  `,
  secondary: css<ApplyVariantProps>`
    /* stylelint-disable */
    transition:
      ${({theme}) => `background-color ${theme.animation}, color ${theme.animation}`};
    /* stylelint-enable */
    border: ${({theme}) => `1px solid ${theme.colors.primary}`};
    padding: 0 1rem;
    color: ${({theme}) => theme.colors.primary};
    ${generateTypography({size: 16, weight: 'semiBold', withResponsive: false})}
    path,
    g {
      transition: ${({theme}) => `fill ${theme.animation}`};
      fill: ${({theme}) => theme.colors.primary};
    }

    ${({$disabled}) => !$disabled && css`
      &:hover,
      &:focus {
        background-color: ${({theme}) => theme.colors.primary};
        color: ${({theme}) => theme.colors.textInverted};

        path,
        g {
          fill: ${({theme}) => theme.colors.textInverted};
        }
      }
    `}
  `,
  plain: css<ApplyVariantProps>`
    transition: ${({theme}) => `color ${theme.animation}`};
    height: auto;
    color: ${({theme}) => theme.colors.primary};
    ${generateTypography({size: 16, withResponsive: false})}
    path,
    g {
      transition: ${({theme}) => `fill ${theme.animation}`};
      fill: ${({theme}) => theme.colors.primary};
    }

    ${({$disabled}) => !$disabled && css`
      &:hover,
      &:focus {
        text-decoration: underline;
        color: ${({theme}) => theme.colors.primary2};

        path,
        g {
          fill: ${({theme}) => theme.colors.primary2};
        }
      }
    `}
  `,
  text: css<ApplyVariantProps>`
    display: inline-block;
    padding: 0;
    height: auto;
    text-decoration: underline;
    color: ${({theme}) => theme.colors.text2};
    font-weight: 600;

    ${({$disabled}) => !$disabled && css`
      &:hover,
      &:focus {
        opacity: 0.8;
      }
    `}
  `,
} as const

const sizes = {
  default: css`
    height: ${px2rem(50)};
  `,
  slim: css`
    height: ${px2rem(40)};
  `,
} as const

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: ${px2rem(5)};
`

const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: ${({theme}) => theme.border.radius.sm};
  background-color: transparent;
  cursor: pointer;
  white-space: nowrap;

  ${({$size}) => $size && sizes[$size]}

  ${({$fullWidth}) => $fullWidth && css`
    width: 100%;
  `}

  ${({$variant}) => $variant && applyVariant[$variant]}

  ${({$disabled}) => $disabled && css`
    opacity: 0.15;
    background-color: ${({theme}) => theme.colors.auxiliary7};
    cursor: not-allowed;
  `}
`

const Button = <T extends ElementType = 'button'>({
  variant = 'primary', size = 'default', type, icon, fullWidth, disabled, onClick, component, isLink, children,
  ...props
}: ButtonProps<T> & ButtonElementProps) => {
  return (
    <StyledButton
        type={type ? type : isLink ? undefined : 'button'}
        $variant={variant}
        $size={size}
        $fullWidth={fullWidth}
        $disabled={disabled}
        onClick={disabled || isLink ? undefined : onClick}
        as={component}
        {...props}
    >
      <If condition={icon}>
        <IconWrapper>
          {icon}
        </IconWrapper>
      </If>
      <If condition={children}>
        <span>{children}</span>
      </If>
    </StyledButton>
  )
}

export const variantKeys = keys(applyVariant)
export const sizesKeys = keys(sizes)
export type Variant = keyof typeof applyVariant
export type Size = keyof typeof sizes

export default Button
