export const VEHICLE_TYPE_CAR_M1 = 'carM1'
export const VEHICLE_TYPE_CAR_N1 = 'carN1'
export const VEHICLE_TYPE_MOTORCYCLE = 'motorcycle'
export const VEHICLE_TYPE_TRAILER = 'trailer'
export const ALL_VEHICLE_TYPES = [
  VEHICLE_TYPE_CAR_M1,
  VEHICLE_TYPE_CAR_N1,
  VEHICLE_TYPE_MOTORCYCLE,
  VEHICLE_TYPE_TRAILER,
] as const

export type VehicleType = typeof ALL_VEHICLE_TYPES[number]

export const SUPPORTED_VEHICLE_TYPES = [
  VEHICLE_TYPE_CAR_M1,
  VEHICLE_TYPE_CAR_N1,
] as const

export type SupportedVehicleType = typeof SUPPORTED_VEHICLE_TYPES[number]
