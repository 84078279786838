export const BODYWORK_SEDAN = 'sedan'
export const BODYWORK_COMBI = 'combi'
export const BODYWORK_HATCHBACK = 'hatchback'
export const BODYWORK_LIMOUSINE = 'limousine'
export const BODYWORK_MPV = 'mpv'
export const BODYWORK_CONVERTIBLE = 'convertible'
export const BODYWORK_COUPE = 'coupe'
export const BODYWORK_DELIVERY_VAN = 'delivery_van'
export const BODYWORK_FURGON = 'furgon'
export const BODYWORK_BUS = 'bus'
export const BODYWORK_CHASSIS_CAB = 'chassis_cab'
export const BODYWORK_PICKUP = 'pickup'
export const BODYWORK_PLATFORM_TRUCK = 'platform_truck'
export const BODYWORK_ROADSTER = 'roadster'
export const BODYWORK_TARGA = 'targa'
export const ALL_BODYWORKS = [
  BODYWORK_SEDAN,
  BODYWORK_COMBI,
  BODYWORK_HATCHBACK,
  BODYWORK_LIMOUSINE,
  BODYWORK_MPV,
  BODYWORK_CONVERTIBLE,
  BODYWORK_COUPE,
  BODYWORK_DELIVERY_VAN,
  BODYWORK_FURGON,
  BODYWORK_BUS,
  BODYWORK_CHASSIS_CAB,
  BODYWORK_PICKUP,
  BODYWORK_PLATFORM_TRUCK,
  BODYWORK_ROADSTER,
  BODYWORK_TARGA,
] as const
