import {oneLine} from 'common-tags'
import {
  BRAND_NOT_FOUND_ERROR, MODEL_NOT_FOUND_ERROR, VALIDATION_ERROR, ZIP_CODE_CITY_PAIR_ERROR,
} from '../../../constants/errorCodes'
import t from '../translations'


type Error = {
  errorCode: string,
  details?: {
    vehicleType?: string,
    vehicleUsage?: string,
    fuel?: string,
  },
}

type Values = {
  vehicleType?: string,
  vehicleUsage?: string,
  brand?: string,
  model?: string,
  zipCode?: string,
  fuel?: string,
}

export const formatErrorMessage = (error: Error, values: Values) => {
  if (!error) return t('form.errorGlobal')
  if (error.errorCode === VALIDATION_ERROR &&
    (error.details?.vehicleType || error.details?.vehicleUsage || error.details?.fuel) ||
    error.errorCode === BRAND_NOT_FOUND_ERROR || error.errorCode === MODEL_NOT_FOUND_ERROR ||
    error.errorCode === ZIP_CODE_CITY_PAIR_ERROR) {
    const errorMessage = error.errorCode === VALIDATION_ERROR && error.details?.vehicleType
      ? `${t(`form.${values.vehicleType}`)}`
      : error.errorCode === VALIDATION_ERROR && error.details?.fuel
        ? `${t('form.fuel')} "${t(`enums.${values.fuel}`)}"`
        : error.errorCode === VALIDATION_ERROR && error.details?.vehicleUsage
          ? `${t('form.vehicleWithUsage')} "${t(`enums.${values.vehicleUsage}`)}"`
          : error.errorCode === BRAND_NOT_FOUND_ERROR ? `značku ${values.brand}`
            : error.errorCode === MODEL_NOT_FOUND_ERROR ? `model ${values.model}`
              : error.errorCode === ZIP_CODE_CITY_PAIR_ERROR ? `PSČ ${values.zipCode}`
                : 'zvolené parametre'
    /* eslint-disable indent */
    return oneLine`
      Momentálne nie je možné dojednať poistenie pre
      ${errorMessage}.
      Kontaktujte nás, prosím, telefonicky na čísle 0918 891 352 alebo mailom na
      <a href="mailto:info@ferovepzp.sk">info@ferovepzp.sk</a>.
      Ospravedlňujeme sa za výpadok.
    `
    /* eslint-enable indent */
  }
  return t('form.errorGlobal')
}
