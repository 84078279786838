import {isNumber} from 'lodash-es'
import type {ThemedCssFunction} from 'styled-components'
import {css} from 'styled-components'
import type {Breakpoint, Theme} from '../theme/theme'
import theme from '../theme/theme'


type Styles = ReturnType<ThemedCssFunction<Theme>>

type MediaBreakpoint = Breakpoint | number

const chooseBreakpoint = (breakpoint: MediaBreakpoint) => {
  return isNumber(breakpoint) ? breakpoint : theme.responsive.breakpoints[breakpoint]
}

const media = ({
  down: (breakpoint: MediaBreakpoint) => (styles: Styles) => css`
    @media screen and (max-width: ${chooseBreakpoint(breakpoint)}px) {
      ${styles}
    }
  `,
  up: (breakpoint: MediaBreakpoint) => (styles: Styles) => css`
    @media screen and (min-width: ${chooseBreakpoint(breakpoint)}px) {
      ${styles}
    }
  `,
  between: (startPoint: MediaBreakpoint, endPoint: Breakpoint) => (styles: Styles) => css`
    @media screen
      and (min-width: ${chooseBreakpoint(startPoint)}px)
      and (max-width: ${chooseBreakpoint(endPoint)}px) {
      ${styles}
    }
  `,
})

export default media
