import {EventStreamContentType, fetchEventSource} from '@microsoft/fetch-event-source'
import type {CalculationResult, PackageCoverages} from 'backend/src/responses/calculation'
import {format} from 'date-fns'
import invariant from 'invariant'
import {isEmpty, isUndefined, omitBy, omit, partition, get, toUpper, isFunction, noop, set, first} from 'lodash-es'
import type {FC, MutableRefObject, ReactNode} from 'react'
import {createContext, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react'
import * as schemas from '../../../common/schemas'
import type {CalculationUuid, ExtendedCalculation} from '../../../common/schemas/calculations'
import {ADDON_DEFAULT} from '../../../constants/defaultAddons'
import * as legalForms from '../../../constants/legalFormTypes'
import * as offers from '../../../constants/offers'
import {OFFER_PACKAGE} from '../../../constants/offers'
import type {PaymentFrequency} from '../../../constants/paymentFrequencies'
import * as paymentFrequencies from '../../../constants/paymentFrequencies'
import t from '../translations'
import {formatErrorMessage} from '../utils/errors'
import {parseDate, validator} from '../utils/forms'
import useAlert from './useAlert'
import useLoading from './useLoading'
import useStoredForm from './useStoredForm'
import type {StoredForm} from './useStoredForm'


export class CalculationError extends Error {
  status: number
}

type OriginalPrices = {
  [period in PaymentFrequency]: {
    originalTerm: number
    originalTotal: number
  } | {
    originalTerm?: undefined
    originalTotal?: undefined
  }
}

export type Addon = Omit<CalculationResult & {
  offer: 'addon'
} & OriginalPrices, 'offer'>

export type Package = Omit<CalculationResult & {
  offer: 'package'
  addons: Addon[]
} & OriginalPrices, 'offer'>


export const validate = (passedValues: Record<string, unknown>) => {
  const result = validator(schemas.calculations.extendedCalculation)(passedValues)
  const errors = result.errors || {}

  return {values: result.values, errors}
}

export const formatLegalFormValues = (values: StoredForm) => {
  if (values.legalForm === legalForms.PRIVATE_PERSON) {
    return {
      legalForm: legalForms.PRIVATE_PERSON,
      zipCode: 'zipCode' in values ? values.zipCode : undefined,
      city: 'city' in values ? values.city : undefined,
      birthday: 'birthday' in values && values.birthday ? format(new Date(values.birthday), 'dd.MM.yyyy') : undefined,
      bornNumber: 'bornNumber' in values ? values.bornNumber : undefined,
      companyNumber: undefined,
      ztp: values.ztp,
      unionUzpDiscount: 'unionUzpDiscount' in values ? values.unionUzpDiscount : undefined,
    } as const
  }
  if (values.legalForm === legalForms.COMPANY) {
    return {
      legalForm: legalForms.COMPANY,
      zipCode: 'zipCode' in values ? values.zipCode : undefined,
      city: 'city' in values ? values.city : undefined,
      birthday: undefined,
      bornNumber: undefined,
      companyNumber: 'companyNumber' in values ? values.companyNumber : undefined,
      ztp: undefined,
      unionUzpDiscount: undefined,
    } as const
  }
  if (values.legalForm === legalForms.SELF_EMPLOYED) {
    return {
      legalForm: legalForms.SELF_EMPLOYED,
      zipCode: 'zipCode' in values ? values.zipCode : undefined,
      city: 'city' in values ? values.city : undefined,
      birthday: undefined,
      bornNumber: undefined,
      companyNumber: 'companyNumber' in values ? values.companyNumber : undefined,
      ztp: undefined,
      unionUzpDiscount: undefined,
    } as const
  }
  invariant(false, `Unknown legal form ${values.legalForm satisfies never}`)
}

export const formatValues = (values: StoredForm) => ({
  ...values,
  ...formatLegalFormValues(values),
} as const)

export const parseLegalFormFormCalculation = (values: ExtendedCalculation) => {
  if (values.legalForm === legalForms.PRIVATE_PERSON) {
    return {
      legalForm: legalForms.PRIVATE_PERSON,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      birthday: parseDate(values.birthday)!,
      bornNumber: values.bornNumber,
      companyNumber: undefined,
      ztp: values.ztp,
      unionUzpDiscount: values.unionUzpDiscount,
    } as const
  }

  if (values.legalForm === legalForms.COMPANY) {
    return {
      legalForm: legalForms.COMPANY,
      birthday: undefined,
      bornNumber: undefined,
      companyNumber: values.companyNumber,
      ztp: undefined,
      unionUzpDiscount: undefined,
    } as const
  }

  if (values.legalForm === legalForms.SELF_EMPLOYED) {
    return {
      legalForm: legalForms.SELF_EMPLOYED,
      birthday: undefined,
      bornNumber: undefined,
      companyNumber: values.companyNumber,
      ztp: undefined,
      unionUzpDiscount: undefined,
    } as const
  }
}

export const parseValues = (values: ExtendedCalculation): ExtendedCalculation => ({
  ...values,
  ...parseLegalFormFormCalculation(values),
  licensePlate: values.licensePlate && !isEmpty(values.licensePlate)
    ? toUpper(values.licensePlate.replace(' ', ''))
    : undefined,
  vin: !isEmpty(values.vin) ? toUpper(values.vin) : undefined,
})

export const getQueryString = (query: Values) => {
  const searchParams = omitBy(query, isUndefined)
  return !isEmpty(searchParams) ? `?${new window.URLSearchParams(searchParams).toString()}` : ''
}


const updatePackages = (packages: Package[], offer: Extract<CalculationResult, {offer: 'package'}>) => {
  const partitionedPackages = partition(packages, ({insurance, packageCoverage}) => {
    return insurance !== offer.insurance || packageCoverage !== offer.packageCoverage
  })

  const [packagesWithoutCurrent, packagesWithCurrent] = partitionedPackages
  const currentPackage = first(packagesWithCurrent) || null

  if (offer.yearly.pending || (!currentPackage?.yearly.pending && offer.yearly.error)) {
    return packages
  }

  const formattedPackage = {
    ...omit(offer, ['offer']),
    addons: [],
  }

  set(
    formattedPackage,
    'yearly.originalTerm',
    currentPackage?.yearly.originalTerm || offer.yearly.term
  )
  set(
    formattedPackage,
    'yearly.originalTotal',
    currentPackage?.yearly.originalTotal || offer.yearly.total
  )

  set(
    formattedPackage,
    'semiAnnually.originalTerm',
    currentPackage?.semiAnnually.originalTerm || offer.semiAnnually.term
  )
  set(
    formattedPackage,
    'semiAnnually.originalTotal',
    currentPackage?.semiAnnually.originalTotal || offer.semiAnnually.total
  )

  set(
    formattedPackage,
    'quarterly.originalTerm',
    currentPackage?.quarterly.originalTerm || offer.quarterly.term
  )
  set(
    formattedPackage,
    'quarterly.originalTotal',
    currentPackage?.quarterly.originalTotal || offer.quarterly.total
  )

  set(
    formattedPackage,
    'addons',
    currentPackage?.addons ?? []
  )

  return [...packagesWithoutCurrent, formattedPackage] satisfies Package[]
}

type Values = ExtendedCalculation | {uuid: CalculationUuid}

const pendingResult = {pending: true as const}

const pendingResults = {
  [paymentFrequencies.PAYMENT_FREQUENCY_YEARLY]: pendingResult,
  [paymentFrequencies.PAYMENT_FREQUENCY_SEMIANNUALLY]: pendingResult,
  [paymentFrequencies.PAYMENT_FREQUENCY_QUARTERLY]: pendingResult,
}

const createPendingPackage = <
  I extends PackageCoverages['insurance'],
  PC extends PackageCoverages['packageCoverages'][number],
>({
    insurance,
    packageCoverage,
  }:{
  insurance: I,
  packageCoverage: PC
}) => {
  return {insurance, offer: OFFER_PACKAGE, addons: [], packageCoverage, ...pendingResults} as Package
}

const updateAddons = (packages: Package[], offer: Extract<CalculationResult, {offer: 'addon'}>) => {
  const defaultAddonCoverage = get(ADDON_DEFAULT, [offer.insurance, offer.addon])
  if (!isEmpty(defaultAddonCoverage) && defaultAddonCoverage !== offer.addonCoverage) {
    return packages
  }

  const formattedAddon = omit(offer, ['offer', 'insurance', 'packageCoverage']) as Addon

  const partitionedPackages = partition(packages, ({insurance, packageCoverage}) => {
    return insurance !== offer.insurance || packageCoverage !== offer.packageCoverage
  })

  const [packagesWithoutCurrent, packagesWithCurrent] = partitionedPackages
  const currentPackage = first(packagesWithCurrent) || createPendingPackage(offer)


  if (offer.yearly.pending || (!currentPackage.yearly.pending && offer.yearly.error)) {
    return packages
  }

  const partitionedAddons = partition(currentPackage.addons, ({addon, addonCoverage}) => {
    return addon !== offer.addon || addonCoverage !== offer.addonCoverage
  })

  const [addonsWithoutCurrent, addonsWithCurrent] = partitionedAddons
  const currentAddon = first(addonsWithCurrent) || null

  set(
    formattedAddon,
    'yearly.originalTerm',
    currentAddon?.yearly.originalTerm || offer.yearly.term
  )
  set(
    formattedAddon,
    'yearly.originalTotal',
    currentAddon?.yearly.originalTotal || offer.yearly.total
  )

  set(
    formattedAddon,
    'semiAnnually.originalTerm',
    currentAddon?.semiAnnually.originalTerm || offer.semiAnnually.term
  )
  set(
    formattedAddon,
    'semiAnnually.originalTotal',
    currentAddon?.semiAnnually.originalTotal || offer.semiAnnually.total
  )

  set(
    formattedAddon,
    'quarterly.originalTerm',
    currentAddon?.quarterly.originalTerm || offer.quarterly.term
  )
  set(
    formattedAddon,
    'quarterly.originalTotal',
    currentAddon?.quarterly.originalTotal || offer.quarterly.total
  )

  currentPackage.addons = [...addonsWithoutCurrent, formattedAddon] as const
  return [...packagesWithoutCurrent, currentPackage] satisfies Package[]
}

type Options = {
  reset?: boolean
  signal?: AbortSignal
  onError?: (error: Error, calculationUuid: CalculationUuid | null) => void
  onOpen?: (res: Response, calculationUuid: CalculationUuid) => void
  onPackage?: (offer: CalculationResult) => void
  onClose?: (calculationUuid: CalculationUuid | null) => void
}

type CalculateContextType = {
  packages: Package[]
  calculationUuid: CalculationUuid | null
  isFetching: boolean
  calculate: (values: Values, options?: Options) => Promise<void>
  onAddon: MutableRefObject<(offer: CalculationResult) => void>
  error: Error | null
}

const CalculateContext = createContext<CalculateContextType | null>(null)

type CalculateProviderProps = {
  children: ReactNode
}

export const CalculateProvider: FC<CalculateProviderProps> = ({children}) => {
  const [packages, setPackages] = useState<Package[]>([])
  const {resetForm} = useStoredForm()
  const {setLoading} = useLoading()
  const {showAlert} = useAlert()
  const [isFetching, setFetching] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [calculationUuid, setCalculationUuid] = useState<CalculationUuid | null>(null)
  const onAddon = useRef(noop)

  const calculate = useCallback(async (values: Values, options: Options = {}) => {
    const search = getQueryString(values)
    if (options.reset) {
      setCalculationUuid(null)
      setPackages([])
    }
    setFetching(true)
    setError(null)

    // eslint-disable-next-line no-process-env
    return fetchEventSource(`${process.env.GATSBY_API_URL}/api/calculate/sse${search}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'text/event-stream',
      },
      openWhenHidden: true,
      signal: options.signal,
      onopen: async (res) => {
        if (!res.ok || !(res.headers.get('content-type') === EventStreamContentType)) {
          const resultError = await res.json()
          const error = new CalculationError(res.statusText || 'Unknown error', {cause: resultError})
          setError(error)
          if (options.onError) {
            options.onError(error, null)
          }
          error.status = res.status
          throw error
        }
        const calculationUuid = res.headers.get('x-calculation-uuid') as CalculationUuid | null

        invariant(calculationUuid, 'Calculation UUID must be present in successful response')

        setCalculationUuid(calculationUuid)

        if (options.onOpen) {
          options.onOpen(res, calculationUuid)
        }
      },
      onmessage: (event) => {
        const offer = JSON.parse(event.data) as CalculationResult

        if (!offer || isEmpty(offer)) return

        if (offer.offer === offers.OFFER_PACKAGE) {
          if (options.onPackage) {
            options.onPackage(offer)
          }
          setPackages((packages) => updatePackages(packages, offer))
        }
        if (offer.offer === offers.OFFER_ADDON) {
          if (onAddon.current) {
            onAddon.current(offer)
          }
          setPackages((packages) => updateAddons(packages, offer))
        }
      },
      onclose: () => {
        setFetching(false)
        if (options.onClose) {
          options.onClose(calculationUuid)
        }
      },
      onerror: (err) => {
        setError(err)
        if (options.onError) {
          options.onError(err, calculationUuid)
        }
        // Unrecoverable error
        if (err.status >= 400 && err.status < 500 && err.status !== 429) {
          resetForm()
          setFetching(false)
          showAlert('uuid' in values ? t('form.errorGlobal') : formatErrorMessage(err.cause, values), {
            buttonText: t('form.errorButton'),
          })
          setLoading(false)
          throw err
        }
      },
    })
  }, [calculationUuid, setLoading, onAddon, resetForm, showAlert])

  const contextValue = useMemo(() => ({
    packages,
    calculationUuid,
    isFetching,
    calculate,
    onAddon,
    error,
  }), [packages, calculationUuid, calculate, isFetching, error])

  return (
    <CalculateContext.Provider value={contextValue}>
      {children}
    </CalculateContext.Provider>
  )
}

type UseCalculateOptions = {
  onAddon?: (offer: Extract<CalculationResult, {offer: 'addon'}>) => void
}

const useCalculate = ({onAddon}:UseCalculateOptions = {}) => {
  const ctx = useContext(CalculateContext)

  invariant(ctx, 'useCalculate must be used within a CalculateProvider')

  const {calculate, calculationUuid, onAddon: onAddonRef, isFetching, error} = ctx

  useEffect(() => {
    if (isFunction(onAddon)) {
      onAddonRef.current = onAddon
    }

    return () => {
      onAddonRef.current = noop
    }
  }, [onAddonRef, onAddon])

  const handleCalculate = useCallback(async (values: Values, options: Options = {}) => {
    return await calculate(values, options)
  }, [calculate])

  return {calculate: handleCalculate, calculationUuid, isFetching, error}
}

export const usePackages = () => {
  const ctx = useContext(CalculateContext)
  invariant(ctx, 'usePackages must be used within a CalculateProvider')
  return ctx.packages
}

export default useCalculate
