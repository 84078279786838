import {isUndefined, isFunction} from 'lodash-es'
import {useCallback, useState} from 'react'
import * as storage from '../utils/storage'


const loadState = (key: string) => {
  if (typeof window !== 'undefined') {
    try {
      return storage.get(window.sessionStorage, key)
    } catch (error) {
      return null
    }
  }
}

const storeState = <Value>(key: string, value: Value) => {
  storage.set(window.sessionStorage, key, value)
  return value
}

const getInitialState = <Value>(key: string, initialValue: Value) => {
  const storedState = loadState(key)
  return isUndefined(storedState) ? initialValue : storedState
}

const useStoredState = <Value>(initialValue: Value, key: string) => {
  const [state, setState] = useState<Value>(getInitialState(key, initialValue))

  const setStoredState = useCallback((valueOrFunction: Value | ((arg: Value) => Value)) => {
    if (typeof window !== 'undefined') {
      const nextValue = isFunction(valueOrFunction) ? valueOrFunction(state) : valueOrFunction
      const storedValue = storeState(key, nextValue)
      return setState(storedValue)
    }
  }, [key, state])

  const getStoredState = useCallback(() => loadState(key) as Value, [key])

  return [state, setStoredState, getStoredState] as const
}

export default useStoredState
