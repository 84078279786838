import type {ComponentProps, FC} from 'react'
import {ThemeProvider as StyledThemeProvider} from 'styled-components'
import GlobalStyles from './GlobalStyles'
import theme from './theme'


type ThemeProviderProps = Omit<ComponentProps<typeof StyledThemeProvider>, 'theme'>

const ThemeProvider: FC<ThemeProviderProps> = ({children, ...props}) => (
  <StyledThemeProvider theme={theme} {...props}>
    <GlobalStyles />
    {children}
  </StyledThemeProvider>
)

export default ThemeProvider
