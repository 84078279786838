import * as csobAddons from './csob/addons'
import * as generaliAddons from './generali/addons'
import * as genertelAddons from './genertel/addons'
import {
  INSURANCE_CSOB, INSURANCE_GENERALI, INSURANCE_GENERTEL, INSURANCE_KOMUNALNA, INSURANCE_UNION, INSURANCE_UNIQA,
  INSURANCE_WUSTENROT,
} from './insurances'
import * as komunalnaAddons from './komunalna/addons'
import * as unionAddons from './union/addons'
import * as uniqaAddons from './uniqa/addons'
import * as wustenrotAddons from './wustenrot/addons'


export const ADDON_DEFAULT = {
  [INSURANCE_CSOB]: {
    [csobAddons.ADDON_GLASS]: csobAddons.GLASS_COVERAGE_DEFAULT,
  },
  [INSURANCE_GENERALI]: {
    [generaliAddons.ADDON_GLASS]: generaliAddons.GLASS_COVERAGE_DEFAULT,
  },
  [INSURANCE_GENERTEL]: {
    [genertelAddons.ADDON_MINICASCO]: genertelAddons.MINICASCO_COVERAGE_DEFAULT,
    [genertelAddons.ADDON_THEFT]: genertelAddons.THEFT_COVERAGE_DEFAULT,
  },
  [INSURANCE_KOMUNALNA]: {
    [komunalnaAddons.ADDON_ANIMAL]: komunalnaAddons.ANIMAL_COVERAGE_DEFAULT,
    [komunalnaAddons.ADDON_ASSISTANCE]: komunalnaAddons.ASSISTANCE_COVERAGE_DEFAULT,
    [komunalnaAddons.ADDON_GLASS]: komunalnaAddons.GLASS_COVERAGE_DEFAULT,
    [komunalnaAddons.ADDON_THEFT]: komunalnaAddons.THEFT_COVERAGE_DEFAULT,
  },
  [INSURANCE_UNION]: {
    [unionAddons.ADDON_ANIMAL]: unionAddons.ANIMAL_LIMIT_TYPE_DEFAULT,
    [unionAddons.ADDON_ASSISTANCE]: unionAddons.ASSISTANCE_TYPE_DEFAULT,
    [unionAddons.ADDON_GLASS]: unionAddons.GLASS_LIMIT_TYPE_DEFAULT,
    [unionAddons.ADDON_INJURY]: unionAddons.INJURY_TYPE_DEFAULT,
  },
  [INSURANCE_UNIQA]: {
    [uniqaAddons.ADDON_ANIMAL]: uniqaAddons.ANIMAL_COVERAGE_DEFAULT,
    [uniqaAddons.ADDON_ELEMENT]: uniqaAddons.ELEMENT_COVERAGE_DEFAULT,
    [uniqaAddons.ADDON_GLASS]: uniqaAddons.GLASS_COVERAGE_DEFAULT,
    [uniqaAddons.ADDON_INJURY]: uniqaAddons.INJURY_COVERAGE_DEFAULT,
    [uniqaAddons.ADDON_LUGGAGE]: uniqaAddons.LUGGAGE_COVERAGE_DEFAULT,
    [uniqaAddons.ADDON_THEFT]: uniqaAddons.THEFT_COVERAGE_DEFAULT,
    [uniqaAddons.ADDON_VANDALISM]: uniqaAddons.VANDALISM_COVERAGE_DEFAULT,
  },
  [INSURANCE_WUSTENROT]: {
    [wustenrotAddons.ADDON_GLASS]: wustenrotAddons.GLASS_COVERAGE_DEFAULT,
  },
}
